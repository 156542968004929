<mat-toolbar color="primary">
  <!-- select supplier -->
  <button
    mat-icon-button
    [routerLink]="['/']"
    routerLinkActive="router-link-active"
    (click)="onBackClick()"
  >
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <ng-container *ngIf="!(multipleSuppliers$ | async)">
    <mat-select
      #select
      (selectionChange)="onSupplierSelectedChange($event)"
      placeholder="Choose A Supplier"
      [value]="selectedSupplierID$ | async"
    >
      <mat-option
        *ngFor="let supplier of supplierList$ | async"
        [value]="supplier.id"
      >
        {{ supplier.name }}
      </mat-option>
    </mat-select>
    <mat-progress-spinner
      style="margin-top: 2px"
      mode="indeterminate"
      color="accent"
      diameter="60"
      *ngIf="!(supplierList$ | async)?.length"
    ></mat-progress-spinner>
    <!-- multiple suppliers setting -->
    <mat-slide-toggle
      *ngIf="!isHandset"
      [checked]="multipleSuppliers$ | async"
      (change)="onMultipleSuppliersSettingChanged($event, select.value)"
      >Multiple Suppliers</mat-slide-toggle
    >
  </ng-container>
  <ng-container *ngIf="multipleSuppliers$ | async">
    <!-- select multiple suppliers -->
    <mat-select
      #multiSelect
      (selectionChange)="onSupplierSelectedChange($event)"
      placeholder="Choose A Supplier"
      [multiple]="true"
      [value]="selectedSuppliersIDs$ | async"
    >
      <mat-option
        *ngFor="let supplier of supplierList$ | async"
        [value]="supplier.id"
      >
        {{ supplier.name }}
      </mat-option>
    </mat-select>
    <span *ngIf="showDetails$ | async"></span>
    <!-- multiple suppliers setting -->
    <mat-slide-toggle
      *ngIf="!(showDetails$ | async) && !isHandset"
      [checked]="multipleSuppliers$ | async"
      (change)="onMultipleSuppliersSettingChanged($event, multiSelect.value[0])"
      >Multiple Suppliers</mat-slide-toggle
    >
  </ng-container>
  <!-- go to styles page -->
  <button
    mat-icon-button
    [routerLink]="['/styles']"
    routerLinkActive="router-link-active"
  >
    <mat-icon>style</mat-icon>
  </button>
</mat-toolbar>
