import { NamedDateRange } from './../models/DateRange';
import { BehaviorSubject, Observable } from 'rxjs';
import { Partition } from './../models/Partition';
import { EndpointsService } from './endpoints.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PartitionType } from '../models/PartitionType';
import { tap } from 'rxjs/operators';
import {
  AccumulationType,
  CalculationType,
  Label,
  Option,
} from 'src/app/sales/models/ChartData';
import { CustomerPartitionTableEntity } from '../models/CustomerEntity';
import { log } from 'console';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient, private endpoints: EndpointsService) {}

  //partitions
  private _partitionTypes: BehaviorSubject<PartitionType[]> =
    new BehaviorSubject<PartitionType[]>([
      { name: 'Suppliers', partitions: [] },
      { name: 'SalesRepresentatives', partitions: [] },
      { name: 'Categories', partitions: [] },
      { name: 'Customers', partitions: [] },
      { name: 'Brands', partitions: [] },
      { name: 'Products', partitions: [] },
    ]);
  partitionTypes$: Observable<PartitionType[]> =
    this._partitionTypes.asObservable();

  getPartitionTypes() {
    const partitionTypeNames = this._partitionTypes
      .getValue()
      .map((partitionType) => partitionType.name.replace(' ', ''));
    for (let i = 0; i < partitionTypeNames.length; i++) {
      this.http
        .get<Partition[]>(
          this.endpoints.getUrl(
            partitionTypeNames[i] == 'Products'
              ? 'ProductSKUs'
              : partitionTypeNames[i]
          )
        )
        .subscribe((partitions) => {
          let partitionTypes = this._partitionTypes.getValue();
          partitionTypes[i].partitions = partitions;
          this._partitionTypes.next(partitionTypes);
        });
    }
    return this.partitionTypes$;
  }

  //dates
  getNamedDateRanges(): NamedDateRange[] {
    let custom: NamedDateRange = {
      name: 'Custom',
      dateRange: { start: new Date(), end: new Date() },
    };
    return [
      this.getLastMonthNamedDateRange(),
      this.getLastQuarterNamedDateRange(),
      this.getLastYearNamedDateRange(),
      this.getThisMonthToDateNamedDateRange(),
      this.getThisQuarterToDateNamedDateRange(),
      this.getThisYearToDateNamedDateRange(),
      this.getCurrentFinancialYearFirstThreeQuartersNamedDateRange(),
      this.getCurrentFinancialYearToDateNamedDateRange(),
      this.getLastFinancialYearNamedDateRange(),
      custom,
    ];
    return [];
  }
  private getThisMonthToDateNamedDateRange() {
    const name: string = 'This Month To Date';
    let namedDateRange: NamedDateRange = {
      name: name,
      dateRange: { start: new Date(), end: new Date() },
    };
    this.setToStartOfMonth(namedDateRange.dateRange.start);
    return namedDateRange;
  }
  private getLastMonthNamedDateRange() {
    const name: string = 'Last Month';
    let namedDateRange: NamedDateRange = {
      name: name,
      dateRange: { start: new Date(), end: new Date() },
    };
    namedDateRange.dateRange.start?.setMonth(
      namedDateRange.dateRange.start.getMonth() - 1
    );
    this.setToStartOfMonth(namedDateRange.dateRange.start);
    this.setToEndOfPreviousMonth(namedDateRange.dateRange.end);
    return namedDateRange;
  }
  private getThisQuarterToDateNamedDateRange() {
    const name: string = 'This Quarter To Date';
    let namedDateRange: NamedDateRange = {
      name: name,
      dateRange: { start: new Date(), end: new Date() },
    };
    const currentQuarterStart = Math.floor(new Date().getMonth() / 3) * 3;
    namedDateRange.dateRange.start?.setMonth(currentQuarterStart);
    this.setToStartOfMonth(namedDateRange.dateRange.start);
    return namedDateRange;
  }
  private getLastQuarterNamedDateRange() {
    const name: string = 'Last Quarter';
    let namedDateRange: NamedDateRange = {
      name: name,
      dateRange: { start: new Date(), end: new Date() },
    };
    const currentQuarterStart = Math.floor(new Date().getMonth() / 3) * 3;
    namedDateRange.dateRange.start?.setMonth(currentQuarterStart - 3);
    this.setToStartOfMonth(namedDateRange.dateRange.start);
    namedDateRange.dateRange.end?.setMonth(currentQuarterStart);
    this.setToEndOfPreviousMonth(namedDateRange.dateRange.end);
    return namedDateRange;
  }
  private getThisYearToDateNamedDateRange() {
    const name: string = 'This Year To Date';
    let namedDateRange: NamedDateRange = {
      name: name,
      dateRange: { start: new Date(), end: new Date() },
    };
    namedDateRange.dateRange.start?.setMonth(0);
    this.setToStartOfMonth(namedDateRange.dateRange.start);
    return namedDateRange;
  }
  private getCurrentFinancialYearFirstThreeQuartersNamedDateRange() {
    const name: string = 'Current FY 1st 3 1/4s';
    let namedDateRange: NamedDateRange = {
      name: name,
      dateRange: { start: new Date(), end: new Date() },
    };

    namedDateRange.dateRange.start?.setMonth(6);
    this.setToStartOfMonth(namedDateRange.dateRange.start);
    namedDateRange.dateRange.end?.setMonth(3);
    this.setToEndOfPreviousMonth(namedDateRange.dateRange.end);
    if (
      (namedDateRange.dateRange.start?.getTime() ?? 0) >
      (namedDateRange.dateRange.end?.getTime() ?? 0)
    ) {
      namedDateRange.dateRange.start?.setFullYear(
        namedDateRange.dateRange.start?.getFullYear() - 1
      );
    }
    return namedDateRange;
  }
  private getCurrentFinancialYearToDateNamedDateRange() {
    const name: string = 'Current FY To Date';
    let namedDateRange: NamedDateRange = {
      name: name,
      dateRange: { start: new Date(), end: new Date() },
    };

    namedDateRange.dateRange.start?.setMonth(6);
    this.setToStartOfMonth(namedDateRange.dateRange.start);
    if (
      (namedDateRange.dateRange.start?.getTime() ?? 0) >
      (namedDateRange.dateRange.end?.getTime() ?? 0)
    ) {
      namedDateRange.dateRange.start?.setFullYear(
        namedDateRange.dateRange.start?.getFullYear() - 1
      );
    }
    return namedDateRange;
  }
  private getLastFinancialYearNamedDateRange() {
    const name: string = 'Last FY';
    let namedDateRange: NamedDateRange = {
      name: name,
      dateRange: { start: new Date(), end: new Date() },
    };
    console.log(namedDateRange.dateRange.start!.getMonth());

    if (namedDateRange.dateRange.start!.getMonth() > 6) {
      namedDateRange.dateRange.start?.setFullYear(
        namedDateRange.dateRange.start.getFullYear() - 1
      );
    } else {
      namedDateRange.dateRange.start?.setFullYear(
        namedDateRange.dateRange.start.getFullYear() - 2
      );
    }
    namedDateRange.dateRange.start?.setMonth(6);
    this.setToStartOfMonth(namedDateRange.dateRange.start);
    namedDateRange.dateRange.end?.setFullYear(
      namedDateRange.dateRange.start!.getFullYear() + 1
    );
    namedDateRange.dateRange.end?.setMonth(6);
    this.setToEndOfPreviousMonth(namedDateRange.dateRange.end);
    return namedDateRange;
  }

  private getLastYearNamedDateRange() {
    const name: string = 'Last Year';
    let namedDateRange: NamedDateRange = {
      name: name,
      dateRange: { start: new Date(), end: new Date() },
    };
    namedDateRange.dateRange.start?.setFullYear(
      namedDateRange.dateRange.start.getFullYear() - 1
    );
    namedDateRange.dateRange.start?.setMonth(0);
    this.setToStartOfMonth(namedDateRange.dateRange.start);

    namedDateRange.dateRange.end?.setMonth(0);
    this.setToEndOfPreviousMonth(namedDateRange.dateRange.end);
    return namedDateRange;
  }
  //sets to the first of the month
  private setToStartOfMonth(date: Date | null) {
    date?.setDate(1);
    date?.setHours(0);
    date?.setMinutes(0);
    date?.setSeconds(0);
    date?.setMilliseconds(0);
  }
  //takes a date in month following the month you want the last date of
  private setToEndOfPreviousMonth(date: Date | null) {
    date?.setDate(0);
    date?.setHours(23);
    date?.setMinutes(59);
    date?.setSeconds(59);
    date?.setMilliseconds(999);
  }

  //options
  public showOptions: string[] = [
    'Top 20',
    'Top 10',
    'Top 5',
    'All',
    'Bottom 10',
    'Bottom 5',
  ];
  public groupingOptions: string[] = [
    'All',
    'Week',
    'Month',
    'Quarter',
    'Year',
  ];
  public xAxisOptions: Label[] = [
    {
      name: 'Sales Representative',
      id: 'salesRepresentative',
      uniqueAcrossInvoice: true,
    },
    { name: 'Brand', id: 'brand', uniqueAcrossInvoice: false },
    { name: 'Supplier Name', id: 'supplierName', uniqueAcrossInvoice: false },
    { name: 'Customer', id: 'customer', uniqueAcrossInvoice: true },
    { name: 'SKU', id: 'sku', uniqueAcrossInvoice: false },
    { name: 'Name', id: 'name', uniqueAcrossInvoice: false },
    { name: 'Category', id: 'category', uniqueAcrossInvoice: false },
    { name: 'Revenue Centre', id: 'revenueCentre', uniqueAcrossInvoice: true },
    { name: 'Customer Class', id: 'customerClass', uniqueAcrossInvoice: true },
    { name: 'State', id: 'state', uniqueAcrossInvoice: true },
  ];

  public yAxisOptions: Option[] = [
    {
      id: 'quantity',
      name: 'Quantity',
      units: 'units',
      unitsPositionBefore: false,
      isCalculated: false,
      calculationParameters: [],
      calculationType: null,
      accumulationType: AccumulationType.addition,
    },
    {
      id: 'lineTotal',
      name: 'Revenue',
      units: '$',
      unitsPositionBefore: true,
      isCalculated: false,
      calculationParameters: [],
      calculationType: null,
      accumulationType: AccumulationType.addition,
    },
    {
      id: 'lineCost',
      name: 'Cost',
      units: '$',
      unitsPositionBefore: true,
      isCalculated: false,
      calculationParameters: [],
      calculationType: null,
      accumulationType: AccumulationType.addition,
    },
    {
      id: 'netWeight',
      name: 'Net Weight',
      units: 'kgs',
      unitsPositionBefore: false,
      isCalculated: false,
      calculationParameters: [],
      calculationType: null,
      accumulationType: AccumulationType.addition,
    },
    {
      id: 'profit',
      name: 'Profit',
      units: '$',
      unitsPositionBefore: true,
      isCalculated: true,
      calculationParameters: ['lineTotal', 'lineCost'],
      calculationType: [CalculationType.subtraction],
      accumulationType: AccumulationType.addition,
    },
    {
      id: 'gp%',
      name: 'GP%',
      units: '%',
      unitsPositionBefore: false,
      isCalculated: true,
      calculationParameters: ['lineTotal', 'lineCost', 'lineTotal', 100],
      calculationType: [
        CalculationType.subtraction,
        CalculationType.division,
        CalculationType.multiplication,
      ],
      accumulationType: AccumulationType.average,
    },
    {
      id: 'cogs',
      name: 'GP% (COGS)',
      units: '%',
      unitsPositionBefore: false,
      isCalculated: true,
      calculationParameters: ['lineTotal', 'cogs', 'lineTotal', 100],
      calculationType: [
        CalculationType.subtraction,
        CalculationType.division,
        CalculationType.multiplication,
      ],
      accumulationType: AccumulationType.average,
    },
  ];

  //table
  public notDisplayedColumns: string[] = [
    'productID',
    'averageCost',
    'lineCost',
    'category',
    'netWeight',
    'locationID',
    'batchSN',
    'expiryDate',
    'saleID',
    'orderDate',
    'invoiceDate',
    'customerID',
    'invoiceNumber',
    'fulfilmentStatus',
    'supplierID',
  ];
  public columnIds = [
    'shipmentDate',
    'supplierName',
    'brand',
    'salesRepresentative',
    'customer',
    'orderNumber',
    'sku',
    'name',
    'price',
    'quantity',
    'discount',
    'lineTotal',
    'orderDate',
    'invoiceDate',
    'customerID',
    'invoiceNumber',
    'fulfilmentStatus',
    'supplierID',
    'productID',
    'averageCost',
    'lineCost',
    'category',
    'netWeight',
    'locationID',
    'batchSN',
    'expiryDate',
    'saleID',
    'cogs',
  ];
}
