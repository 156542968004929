<div class="spacer" [ngClass]="{ narrow: !(handsetPortrait$ | async) }"></div>
<mat-nav-list class="nav-list">
  <!-- Home -->
  <mat-list-item>
    <a
      mat-list-item
      [routerLink]="['/']"
      routerLinkActive="router-link-active"
      (click)="onNavigate()"
      >Home</a
    >
  </mat-list-item>
  <mat-divider></mat-divider>
  <div class="date-range">
    <mat-form-field class="extra-padding">
      <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date" />
        <input matEndDate formControlName="end" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error
        *ngIf="dateRange.controls.start.hasError('matStartDateInvalid')"
        >Invalid start date</mat-error
      >
      <mat-error *ngIf="dateRange.controls.end.hasError('matEndDateInvalid')"
        >Invalid end date</mat-error
      >
    </mat-form-field>
    <!-- date type opitions -->
    <mat-form-field class="extra-padding named-date-range">
      <mat-select
        [value]="getNamedDateRangeName()"
        (valueChange)="setNamedDateRange($event)"
        placeholder=""
      >
        <mat-option
          *ngFor="let namedDateRange of namedDateRanges"
          [value]="namedDateRange"
        >
          {{ namedDateRange.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-divider> </mat-divider>
  <!-- select multiple partitions toggle -->
  <span class="multiple-partitions">
    <mat-slide-toggle
      [checked]="multiplePartitions"
      (change)="onMultiplePartitionsToggleChange($event)"
      class="select-multiple-partitions"
      mat-list-item
      >Select Multiple Partitions</mat-slide-toggle
    >
    <button
      *ngIf="multiplePartitions && selectedPartitions.selected.length"
      mat-raised-button
      color="accent"
      (click)="onSelectedPartitionsGoClick()"
    >
      Go
    </button>
  </span>
  <mat-divider></mat-divider>
  <!-- partition types -->
  <div class="scrollable">
    <ng-container *ngFor="let partitionType of partitionTypes$ | async">
      <mat-list-item
        *ngIf="!expandedPartitionType || isExpanded(partitionType)"
      >
        <a class="partition-type-name" mat-list-item>
          {{ partitionType.name }}
        </a>
        <button
          mat-icon-button
          color="white"
          (click)="toggleExpandPartitionType(partitionType)"
        >
          <mat-icon>
            <ng-container *ngIf="!isExpanded(partitionType)"
              >navigate_next</ng-container
            >
            <ng-container *ngIf="isExpanded(partitionType)"
              >expand_less</ng-container
            >
          </mat-icon>
        </button>
      </mat-list-item>
      <mat-divider></mat-divider>
      <!-- partitions list -->
      <ng-container *ngIf="isExpanded(partitionType)">
        <ng-container *ngIf="options.length">
          <!-- filter partitions -->
          <mat-list-item>
            <form class="extra-padding">
              <mat-form-field>
                <input type="text" matInput [formControl]="partitionFilter" />
              </mat-form-field>
            </form>
            <mat-icon class="mat-18">search</mat-icon>
          </mat-list-item>
          <mat-divider></mat-divider>
          <!-- partitions -->

          <ng-container *ngFor="let partition of filteredOptions | async">
            <mat-list-item>
              <!-- select multiple partitions  -->
              <mat-checkbox
                *ngIf="multiplePartitions"
                (change)="onMultiplePartitionsSelectionChange($event)"
                [value]="partition.id"
                class="extra-padding"
                >{{ partition.name }}</mat-checkbox
              >
              <!-- go to single partition -->
              <a
                *ngIf="!multiplePartitions"
                class="extra-padding"
                mat-list-item
                [routerLink]="['/salesreports']"
                [queryParams]="{
                  groupBy: removeSpaces(partitionType.name),
                  ids: removeSpaces(partition.id)
                }"
                routerLinkActive="router-link-active"
                (click)="onNavigate()"
                >{{ partition.name }}</a
              >
            </mat-list-item>
            <mat-divider></mat-divider>
          </ng-container>
        </ng-container>
        <!-- loading -->
        <mat-list-item *ngIf="!options.length" class="extra-padding"
          ><p mat-list-item>Loading...</p></mat-list-item
        >
      </ng-container>
    </ng-container>
    <mat-list-item>
      <a
        class="partition-type-name"
        mat-list-item
        [routerLink]="['/salesreports']"
        [queryParams]="{
          groupBy: 'All',
          ids: 'All'
        }"
        routerLinkActive="router-link-active"
      >
        All Sales
      </a>
    </mat-list-item>
  </div>
  <div class="named-settings">
    <mat-label class="extra-padding">Quick Access</mat-label>
    <ng-container *ngFor="let namedSetting of namedSettings$ | async">
      <mat-list-item>
        <a
          class="extra-padding"
          mat-list-item
          (click)="onNamedSettingClick(namedSetting)"
        >
          {{ namedSetting.name }}
        </a>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>
  </div>
</mat-nav-list>
