<!-- if there is a data fetch in progress show loading bar -->
<ng-container *ngIf="dataFetchInProgress$ | async">
  Fetching Data
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
</ng-container>
<!-- if the filter doesnt match -->
<ng-container
  *ngIf="!(dataFetchInProgress$ | async) && (currentFilter$ | async)"
>
  No data matching the filter "{{ currentFilter$ | async }}"
</ng-container>
<!-- if there is not data fetch in progress and there is no filter then there are no products-->
<ng-container
  *ngIf="!(dataFetchInProgress$ | async) && !(currentFilter$ | async)"
>
  No Products
</ng-container>
