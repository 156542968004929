import { BreakpointsService } from 'src/app/shared/services/breakpoints.service';
import { PlannerSettingsService } from './../planner-settings.service';
import { PlannerStaticSettingsService } from './../planner-static-settings.service';
import { PlannerProductData } from './../../models/PlannerProductData';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tickStep } from 'd3';

@Component({
  selector: 'app-planner-cell',
  templateUrl: './planner-cell.component.html',
  styleUrls: ['./planner-cell.component.scss'],
})
export class PlannerCellComponent implements OnInit {
  @Input() columnId: string = '';
  @Input() productData: PlannerProductData = {
    id: '',
    name: '',
    plannerCellDataArray: [],
    category: '',
    sku: '',
    onOrder: 0,
  };
  constructor(
    private plannerStaticSettings: PlannerStaticSettingsService,
    private plannerSettings: PlannerSettingsService,
    private breakpoints: BreakpointsService
  ) {}
  isDevice: boolean = this.breakpoints.isDevice();
  showVal$: Observable<boolean> = this.plannerSettings.showVal();
  showQty$: Observable<boolean> = this.plannerSettings.showQty();
  text: string = '';
  value: number = 0;
  qty: number = 0;
  data: string | number = '';
  ids: string = '';
  name: string = '';
  sku: string = '';
  ngOnInit(): void {
    let cellData = this.productData.plannerCellDataArray.filter(
      (cell) => cell.id == this.columnId
    )[0];
    let data = cellData?.value?.toString() ?? '';
    this.data = data == '0' ? '' : data;
    this.text = cellData?.text ?? '';
    this.value = cellData?.value ?? 0;
    this.qty = cellData?.qty ?? 0;
    this.ids = cellData?.ids ?? '';
    this.name = this.productData.name;
    this.sku = this.productData.sku;
  }

  canShowDetails() {
    return this.plannerStaticSettings.columnsThatCanShowDetails.includes(
      this.columnId.split('_')[0]
    );
  }
  isDetailsColumn() {
    return this.columnId.split('_')[1] == 'ProductDetails';
  }
  isSKUColumn() {
    return this.columnId.split('_')[0] == 'SKU';
  }
  toggleShowDetails() {
    this.plannerSettings.setSalesDetailArgs({
      productId: this.productData.id,
      type: this.columnId.split('_')[0],
      yearMonth: this.columnId.split('_')[1],
      ids: this.ids,
      name: this.name,
      sku: this.sku,
    });
    this.plannerSettings.setShowDetailsSetting(true);
  }
}
