import { Supplier } from './../models/Supplier';
import { SettingsTableEntity } from './../models/SettingsTableEntity';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../shared/services/auth.service';
import { PlannerService } from './planner.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  share,
  shareReplay,
  skip,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { EndpointsService } from '../shared/services/endpoints.service';
import { SalesDetailArgs } from '../models/SalesDetailArgs';
import { blob } from 'd3';
import { PlannerStaticSettingsService } from './planner-static-settings.service';
import { DatePipe } from '@angular/common';
let settings: SettingsTableEntity;
export type settingsKey = keyof typeof settings;
@Injectable({
  providedIn: 'root',
})
export class PlannerSettingsService {
  constructor(
    // private plannerService: PlannerService,
    private authService: AuthService,
    private http: HttpClient,
    private plannerStaticSettings: PlannerStaticSettingsService,
    private endpoints: EndpointsService,
    private datePipe: DatePipe
  ) {}

  private readonly settingsEndpoint: string = 'Settings';
  public readonly selectingColumnsDisplayMessage: string =
    'Selecting Visible Columns';
  private readonly settingsPropertyNames = {
    fromMonth: 'fromMonth',
    fromYearMonth: 'fromYearMonth',
    currentFilter: 'currentFilter',
    pagination: 'pagination',
    savedFilters: 'savedFilters',
    currentExclusion: 'currentExclusion',
    savedExclusions: 'savedExclusions',
    hiddenColumns: 'hiddenColumns',
    productDetailsOrder: 'productDetailsOrder',
    hiddenColumnTypes: 'hiddenColumnTypes',
    cellDisplayParameters: 'cellDisplayParameters',
    groupById: 'groupById',
    showTotals: 'showTotals',
  };
  ///----the following are not saved in the DB
  //salesDetail
  private _salesDetailArgs: BehaviorSubject<SalesDetailArgs | null> =
    new BehaviorSubject<SalesDetailArgs | null>(null);
  private salesDetailArgs$: Observable<SalesDetailArgs | null> =
    this._salesDetailArgs.asObservable();
  getSalesDetailArgs() {
    return this.salesDetailArgs$;
  }
  setSalesDetailArgs(salesDetailArgs: SalesDetailArgs) {
    this._salesDetailArgs.next(salesDetailArgs);
  }

  //scroll position
  private _scrollx: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private scrollx: Observable<number> = this._scrollx.asObservable();
  getScrollx() {
    return this._scrollx.getValue();
  }
  setScrollx(scrollx: number) {
    this._scrollx.next(scrollx);
  }
  //supplier List
  private _supplierList: BehaviorSubject<Supplier[]> = new BehaviorSubject<
    Supplier[]
  >([]);
  public supplierList$: Observable<Supplier[]> =
    this._supplierList.asObservable();

  getSupplierList() {
    return this.supplierList$;
  }
  setSupplierList(suppliers: Supplier[]) {
    this._supplierList.next(suppliers);
  }
  //selected suppliers
  public selectedSuppliers$: Observable<Supplier[]> = this.supplierList$.pipe(
    map((suppliers) => suppliers.filter((supplier) => supplier.selected))
  );
  getSelectedSuppliers() {
    return this.selectedSuppliers$;
  }
  setSelectedSuppliers(supplierIDs: string[]) {
    const suppliers = this._supplierList.getValue();
    if (suppliers.length) {
      suppliers.forEach(function (supplier, index, suppliers) {
        if (supplierIDs.includes(suppliers[index].id)) {
          suppliers[index].selected = true;
        } else {
          suppliers[index].selected = false;
        }
      });
      this._supplierList.next(suppliers);
    } else {
      setTimeout(() => {
        this.setSelectedSuppliers(supplierIDs);
      }, 1000); // check again in a second
    }
  }
  //multiple suppliers
  private _multipleSuppliers: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private multipleSuppliers$: Observable<boolean> =
    this._multipleSuppliers.asObservable();
  getMultipleSuppliersSetting() {
    return this.multipleSuppliers$;
  }
  setMultipleSuppliersSetting(setting: boolean) {
    this._multipleSuppliers.next(setting);
  }

  private _allYearMonths: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);
  private allYearMonths$: Observable<string[]> =
    this._allYearMonths.asObservable();
  getAllYearMonths() {
    return this.allYearMonths$;
  }
  setAllYearMonths(yearMonths: string[]) {
    this._allYearMonths.next(yearMonths);
  }
  //show details
  private _showDetails: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private showDetails$: Observable<boolean> = this._showDetails.asObservable();
  getShowDetailsSetting() {
    return this.showDetails$;
  }
  setShowDetailsSetting(setting: boolean) {
    this._showDetails.next(setting);
  }
  //current supplier
  private _currentSupplier: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  private currentSupplier$: Observable<string> =
    this._currentSupplier.asObservable();
  getCurrentSupplier() {
    return this.currentSupplier$;
  }
  setCurrentSupplier(filter: string) {
    this._currentSupplier.next(filter);
  }

  private _allColumns: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);
  private allColumns$: Observable<string[]> = this._allColumns
    .asObservable()
    .pipe
    // tap((allColumns) =>
    //   this.setProductDetailsOrder(
    //     allColumns
    //       .filter((column) => column.includes('ProductDetails'))
    //       .map((column) => column.split('_')[0])
    //   )
    // )
    ();

  getAllColumns() {
    return this.allColumns$;
  }
  setAllColumns(columns: string[]) {
    this._allColumns.next(columns);
  }
  getCurrentAllColumns() {
    return [...this._allColumns.getValue()];
  }

  // private _updatesAvailable: BehaviorSubject<boolean> =
  //   new BehaviorSubject<boolean>(false);
  // private updatesAvailable$: Observable<boolean> =
  //   this._updatesAvailable.asObservable();
  // getUpdatesAvailableSetting() {
  //   return this.updatesAvailable$;
  // }
  // setUpdatesAvailableSetting(setting: boolean) {
  //   this._updatesAvailable.next(setting);
  // }

  //----the following are saved in the DB
  //settings
  private _settings: BehaviorSubject<SettingsTableEntity> =
    new BehaviorSubject<SettingsTableEntity>({
      pagination: '',
      currentFilter: '',
      savedFilters: [],
      currentExclusion: '',
      savedExclusions: [],
      hiddenColumns: [],
      hiddenColumnTypes: [],
      productDetailsOrder: [],
      groupById: '',
      cellDisplayParameters: ['qty'],
      showTotals: '',
      fromYearMonth: '',
      partitionKey: '',
      rowKey: '',
    });
  private settings$: Observable<SettingsTableEntity> =
    this._settings.asObservable();
  private isValidKey(key: string): key is settingsKey {
    return true;
  }
  private addToSettingList(propertyName: string, setting: any) {
    if (this.isValidKey(propertyName)) {
      let settings: SettingsTableEntity = this._settings.getValue();
      let property = settings ? settings[propertyName] : null;
      if (property && Array.isArray(property)) {
        property.push(setting);
        this._settings.next(settings);
      }
    }
  }
  private removeFromSettingList(propertyName: string, setting: any) {
    if (this.isValidKey(propertyName)) {
      let settings: SettingsTableEntity = this._settings.getValue();
      let property = settings ? settings[propertyName] : null;
      if (property && Array.isArray(property)) {
        property.splice(property.indexOf(setting), 1);
        this._settings.next(settings);
      }
    }
  }
  private updateSettingsValue(propertyName: string, setting: any) {
    if (this.isValidKey(propertyName)) {
      let settings: SettingsTableEntity = this._settings.getValue();
      let property = settings ? settings[propertyName] : null;
      if (property != null) {
        settings[propertyName] = setting;
        this._settings.next(settings);
      }
    }
  }

  //fromYearMonth -----------------------------------------------------

  private fromYearMonth$: Observable<string> = this.settings$.pipe(
    map((settings) => settings.fromYearMonth),
    distinctUntilChanged()
  );

  getFromYearMonthSetting() {
    let startDate = new Date();
    startDate.setMonth(
      startDate.getMonth() - this.plannerStaticSettings.pastMonthsToShow
    );

    //make past months to show an editable option at some point
    return this.fromYearMonth$.pipe(
      startWith(this.datePipe.transform(startDate, 'yyyyMM') ?? ''),
      shareReplay(1),
      distinctUntilChanged()
      // debounceTime(500)
    );
  }
  setFromYearMonthSetting(setting: string) {
    this.updateSettingsValue(this.settingsPropertyNames.fromYearMonth, setting);
  }
  //Pagination
  private pagintion$: Observable<string> = this._settings.pipe(
    map((settings) => settings.pagination)
  );
  getPagination() {
    return this.pagintion$.pipe(distinctUntilChanged(), debounceTime(500));
  }
  setPagination(pagination: string) {
    this.updateSettingsValue(this.settingsPropertyNames.pagination, pagination);
  }

  //filtering
  //current filter
  private currentFilter$: Observable<string> = this._settings.pipe(
    map((settings) => settings.currentFilter)
  );
  getCurrentFilter() {
    return this.currentFilter$.pipe(distinctUntilChanged());
  }
  setCurrentFilter(filter: string) {
    this.updateSettingsValue(this.settingsPropertyNames.currentFilter, filter);
  }

  //product details order
  private productDetailsOrder$: Observable<string[]> = this._settings.pipe(
    map((settings) => {
      if (settings.productDetailsOrder.length) {
        return settings.productDetailsOrder;
      }
      return this.plannerStaticSettings.defaultProducDetailsOrder;
    })
  );
  getProductDetailsOrder() {
    return this.productDetailsOrder$.pipe(distinctUntilChanged());
  }
  setProductDetailsOrder(order: string[]) {
    let keepGoing: boolean = true;
    let i: number = 0;
    while (
      keepGoing &&
      i < this.plannerStaticSettings.defaultProducDetailsOrder.length
    ) {
      if (this.plannerStaticSettings.defaultProducDetailsOrder[i] != order[i]) {
        this.updateSettingsValue(
          this.settingsPropertyNames.productDetailsOrder,
          order
        );
        keepGoing = false;
      }
      i++;
    }
  }

  //saved filters
  private savedFilters$: Observable<string[]> = this._settings.pipe(
    map((settings) => settings.savedFilters)
  );
  getSavedFilters() {
    return this.savedFilters$.pipe(distinctUntilChanged());
  }
  addFilterToSavedFilters(filter: string) {
    this.addToSettingList(this.settingsPropertyNames.savedFilters, filter);
  }
  deleteFilterFromSavedFilters(filter: string) {
    this.removeFromSettingList(this.settingsPropertyNames.savedFilters, filter);
  }

  //exclusions
  //current exclusion
  private currentExclusion$: Observable<string> = this._settings.pipe(
    map((settings) => settings.currentExclusion)
  );
  getCurrentExclusion() {
    return this.currentExclusion$.pipe(distinctUntilChanged());
  }
  setCurrentExclusion(exclusion: string) {
    this.updateSettingsValue(
      this.settingsPropertyNames.currentExclusion,
      exclusion
    );
  }

  //saved exclusions
  private savedExclusions$: Observable<string[]> = this._settings.pipe(
    map((settings) => settings.savedExclusions)
  );
  getSavedExclusions() {
    return this.savedExclusions$.pipe(distinctUntilChanged());
  }
  addExclusionToSavedExclusions(exclusion: string) {
    this.addToSettingList(
      this.settingsPropertyNames.savedExclusions,
      exclusion
    );
  }
  deleteExclusionFromSavedExclusions(exclusion: string) {
    this.removeFromSettingList(
      this.settingsPropertyNames.savedExclusions,
      exclusion
    );
  }

  //groupBy
  private groupById$: Observable<string> = this._settings.pipe(
    map((settings) => settings.groupById)
  );
  getGroupById() {
    return this.groupById$.pipe(distinctUntilChanged());
  }
  setGroupById(groupById: string) {
    // console.log(groupById);
    this.updateSettingsValue(this.settingsPropertyNames.groupById, groupById);
    // console.log(this._settings.getValue());
  }

  //groupBy
  private showTotals$: Observable<string> = this._settings.pipe(
    map((settings) => settings.showTotals)
  );
  getShowTotals() {
    return this.showTotals$.pipe(distinctUntilChanged());
  }
  setShowTotals(showTotals: string) {
    // console.log(showTotals);
    this.updateSettingsValue(this.settingsPropertyNames.showTotals, showTotals);
    // console.log(this._settings.getValue());
  }

  //cell display Type
  private cellDisplayParameters$: Observable<string[]> = this._settings.pipe(
    map((settings) => settings.cellDisplayParameters)
  );
  getCellDisplayParameters() {
    return this.cellDisplayParameters$.pipe(distinctUntilChanged());
  }
  setCellDisplayParameters(cellDisplayParameters: string[]) {
    this.updateSettingsValue(
      this.settingsPropertyNames.cellDisplayParameters,
      cellDisplayParameters
    );
  }
  showQty() {
    return this.getCellDisplayParameters().pipe(
      map((cellDisplayParameters) => cellDisplayParameters.includes('qty'))
    );
  }
  showVal() {
    return this.getCellDisplayParameters().pipe(
      map((cellDisplayParameters) => cellDisplayParameters.includes('val'))
    );
  }

  //hidden column types
  private hiddenColumnTypes$: Observable<string[]> = this._settings.pipe(
    map((settings) => settings.hiddenColumnTypes)
  );
  getHiddenColumnTypes() {
    return this.hiddenColumnTypes$.pipe(distinctUntilChanged());
  }
  hideColumnType(columnType: string) {
    this.addToSettingList(
      this.settingsPropertyNames.hiddenColumnTypes,
      columnType
    );
  }
  unhideColumnType(columnType: string) {
    this.removeFromSettingList(
      this.settingsPropertyNames.hiddenColumnTypes,
      columnType
    );
  }

  //column hiding
  private hiddenColumns$: Observable<string[]> = this._settings.pipe(
    map((settings) => settings.hiddenColumns)
  );
  getHiddenColumns() {
    return this.hiddenColumns$.pipe(distinctUntilChanged());
  }
  hideColumn(column: string) {
    this.addToSettingList(this.settingsPropertyNames.hiddenColumns, column);
  }
  unhideColumn(column: string) {
    this.removeFromSettingList(
      this.settingsPropertyNames.hiddenColumns,
      column
    );
  }
  private displayedColumns$: Observable<string[]> = combineLatest([
    this.hiddenColumns$,
    this.allColumns$,
  ]).pipe(
    map(([hiddenColumns, allColumns]) => {
      return allColumns.filter((column) => hiddenColumns.indexOf(column) < 0);
    })
  );
  getDisplayedColumns() {
    return this.displayedColumns$;
  }

  private _skipNext: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  skipNextSettingsUpdate() {
    this._skipNext.next(true);
  }

  configure() {
    //when settings are changed save to DB
    this.settings$
      .pipe(
        skip(1),
        // tap((settings) => console.log('settings have changed')),
        switchMap((settings: SettingsTableEntity) => {
          return this.getSelectedSuppliers().pipe(
            switchMap((selectedSuppliers: Supplier[]) => {
              if (selectedSuppliers.length) {
                // console.log('valid selected suppliers');
                settings.partitionKey = selectedSuppliers[0].id;
                if (!this._skipNext.getValue()) {
                  // console.log('skip next not true');
                  return this.authService.getLoggedInUserName().pipe(
                    debounceTime(2000),
                    // tap(() => console.log('debounce2000 passed')),
                    switchMap((userName: string) => {
                      if (userName) {
                        let postSettings = { ...settings };
                        // console.log('valid user name');
                        postSettings.rowKey = userName;
                        if (
                          postSettings.currentFilter ==
                          this.selectingColumnsDisplayMessage
                        ) {
                          postSettings.currentFilter = '';
                        }
                        if (
                          postSettings.currentExclusion ==
                          this.selectingColumnsDisplayMessage
                        ) {
                          postSettings.currentExclusion = '';
                        }
                        // console.log('posting settings');
                        return this.http.post<boolean>(
                          this.endpoints.getUrl(this.settingsEndpoint),
                          postSettings
                        );
                      } else {
                        console.log('invalid user name');
                        return of(false);
                      }
                    })
                  );
                } else {
                  // console.log('skip next was true');
                  // console.log('setting it to false');
                  this._skipNext.next(false);
                  return of(false);
                }
              } else {
                console.log('invalid selected suppliers', selectedSuppliers);
                return of(false);
              }
            })
          );
        })
      )
      .subscribe((_) => {
        _
          ? console.log('successfully posted settings')
          : console.log('didnt post settings');
      });
  }

  loadSettings(supplierId: string) {
    console.log('load settings called');

    this.authService
      .getLoggedInUserName()
      .pipe(
        take(1),
        switchMap((userName) => {
          if (!userName) {
            console.log('invalid userName');
          }
          return this.getSettings(userName, supplierId);
        })
      )
      .subscribe((settings) => {
        console.log('got the settings');
        console.log(settings);
        if (
          !settings.cellDisplayParameters.includes('qty') &&
          !settings.cellDisplayParameters.includes('val')
        ) {
          settings.cellDisplayParameters.push('qty');
        }
        this._settings.next(settings);
      });
  }

  getSettings(userName: string, supplierId: string) {
    this.skipNextSettingsUpdate();
    console.log('getting settings');
    return this.http.get<SettingsTableEntity>(
      this.endpoints.getUrl(
        `${this.settingsEndpoint}?supplierId=${supplierId}&user=${userName}`
      )
    );
  }
}
