import { StylesTableEntity } from './../models/StylesTableEntity';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../shared/services/auth.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, skip, switchMap, take, tap } from 'rxjs/operators';
import { EndpointsService } from '../shared/services/endpoints.service';
@Injectable({
  providedIn: 'root',
})
export class PlannerStylesService {
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  private _styles: BehaviorSubject<StylesTableEntity> =
    new BehaviorSubject<StylesTableEntity>({
      verticalGridLines: false,
      horizontalGridLines: false,
      rowKey: '',
      partitionKey: '',
    });
  private styles$: Observable<StylesTableEntity> = this._styles.asObservable();
  private setStyles(styles: StylesTableEntity) {
    this._styles.next(styles);
  }
  private verticalGridLines$: Observable<boolean> = this.styles$.pipe(
    map((styles) => styles.verticalGridLines)
  );
  getVerticalGridLinesSetting() {
    return this.verticalGridLines$;
  }
  setVerticalGridLinesSetting(setting: boolean) {
    let styles = this._styles.getValue();
    styles.verticalGridLines = setting;
    this._styles.next(styles);
  }

  private horizontalGridLines$: Observable<boolean> = this.styles$.pipe(
    map((styles) => styles.horizontalGridLines)
  );
  getHorizontalGridLinesSetting() {
    return this.horizontalGridLines$;
  }
  setHorizontalGridLinesSetting(setting: boolean) {
    let styles = this._styles.getValue();
    styles.horizontalGridLines = setting;
    this._styles.next(styles);
  }

  configure() {
    this.styles$
      .pipe(
        skip(2),
        tap((styles) => console.log('styles have changed', styles)),
        switchMap((styles: StylesTableEntity) =>
          this.authService.getLoggedInUserName().pipe(
            take(1),
            switchMap((userName) => {
              if (userName) {
                styles.rowKey = userName;
                styles.partitionKey = userName;
                console.log('valid userName detected, posting styles', styles);
                return this.http.post<boolean>(
                  this.endpoints.getUrl('Styles'),
                  styles
                );
              } else {
                console.log("don't have a valid username");
                return of(false);
              }
            })
          )
        )
      )
      .subscribe((_) =>
        _
          ? console.log('successfully posted styles')
          : console.log("didn't post or something went wrong")
      );
  }

  loadStyles() {
    this.authService
      .getLoggedInUserName()
      .pipe(
        take(1),
        switchMap((userName) => {
          console.log('getting the styles');
          return this.http.get<StylesTableEntity>(
            this.endpoints.getUrl(`Styles?user=${userName}`)
          );
        })
      )
      .subscribe((styles) => {
        console.log('got the styles');
        // Object.entries(styles).forEach((entry) =>
        //   console.log(`${entry[0]}: ${entry[1]}`)
        // );
        this.setStyles(styles);
      });
  }
}
