import { Injectable } from '@angular/core';
import { SharedModule } from '../shared.module';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { from, Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'any',
})
export class BreakpointsService {
  constructor(private breakpointObserver: BreakpointObserver) {}

  public handset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset])
    .pipe(
      tap((result) => result.breakpoints),
      map((result) => result.matches),
      tap((resultMatches) => {
        if (resultMatches) {
          //  console.log('handset');
        }
      }),
      shareReplay()
    );
  public handsetPortrait$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.HandsetPortrait])
    .pipe(
      tap((result) => result.breakpoints),
      map((result) => result.matches),
      tap((resultMatches) => {
        if (resultMatches) {
          // console.log('handset portrait');
        }
      }),
      shareReplay()
    );
  public handsetLandscape$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.HandsetLandscape])
    .pipe(
      tap((result) => result.breakpoints),
      map((result) => result.matches),
      tap((resultMatches) => {
        if (resultMatches) {
          //  console.log('handset Landscape');
        }
      }),
      shareReplay()
    );
  public tabletLandscape$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.TabletLandscape])
    .pipe(
      tap((result) => result.breakpoints),
      map((result) => result.matches),
      tap((resultMatches) => {
        if (resultMatches) {
          //  console.log('tablet Landscape');
        }
      }),
      shareReplay()
    );
  public xsmall$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .pipe(
      tap((result) => result.breakpoints),
      map((result) => result.matches),
      tap((resultMatches) => {
        if (resultMatches) {
          // console.log('xsmall');
        }
      }),
      shareReplay()
    );
  public small$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Small])
    .pipe(
      tap((result) => result.breakpoints),
      map((result) => result.matches),
      tap((resultMatches) => {
        if (resultMatches) {
          // console.log('small');
        }
      }),
      shareReplay()
    );
  public medium$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Medium])
    .pipe(
      tap((result) => result.breakpoints),
      map((result) => result.matches),
      tap((resultMatches) => {
        if (resultMatches) {
          //  console.log('medium');
        }
      }),
      shareReplay()
    );
  public large$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Large])
    .pipe(
      tap((result) => result.breakpoints),
      map((result) => result.matches),
      tap((resultMatches) => {
        if (resultMatches) {
          //  console.log('large');
        }
      }),
      shareReplay()
    );
  public xlarge$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XLarge])
    .pipe(
      tap((result) => result.breakpoints),
      map((result) => result.matches),
      tap((resultMatches) => {
        if (resultMatches) {
          // console.log('xlarge');
        }
      }),
      shareReplay()
    );

  isHandset() {
    return this.breakpointObserver.isMatched(Breakpoints.Handset);
  }
  isHandsetPortrait() {
    return this.breakpointObserver.isMatched(Breakpoints.HandsetPortrait);
  }
  isDevice() {
    return (
      this.breakpointObserver.isMatched(Breakpoints.Handset) ||
      this.breakpointObserver.isMatched(Breakpoints.Tablet)
    );
  }
  isShort() {
    return this.breakpointObserver.isMatched('(max-height: 599px)');
  }
}
