import { BreakpointsService } from 'src/app/shared/services/breakpoints.service';
import { map, tap } from 'rxjs/operators';
import { Component, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PlannerService } from '../../../planner/planner.service';
import { PlannerSettingsService } from '../../../planner/planner-settings.service';
import { Supplier } from '../../../models/Supplier';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  constructor(
    private plannerService: PlannerService,
    private plannerSettings: PlannerSettingsService,
    private router: Router,
    private breakpoints: BreakpointsService
  ) {}
  @Output() back: EventEmitter<void> = new EventEmitter<void>();
  showDetails$: Observable<boolean> = this.plannerSettings
    .getShowDetailsSetting()
    .pipe
    // tap((_) => console.log(_))
    ();
  selectedSuppliersIDs$: Observable<string[]> = this.plannerSettings
    .getSelectedSuppliers()
    .pipe(
      // tap((_) => console.log(_)),
      map((suppliers) => suppliers.map((supplier) => supplier.id))
      // tap((_) => console.log(_))
    );
  selectedSupplierID$: Observable<string> = this.plannerSettings
    .getSelectedSuppliers()
    .pipe(
      // tap((_) => console.log(_)),
      map((suppliers) => {
        if (suppliers.length) {
          return suppliers[0].id;
        }
        return '';
      })
      // tap((_) => console.log(_))
    );
  isHandset: boolean = this.breakpoints.isHandset();
  selectedSuppliersNames$: Observable<string[]> = this.plannerSettings
    .getSelectedSuppliers()
    .pipe(map((suppliers) => suppliers.map((supplier) => supplier.name)));
  multipleSuppliers$: Observable<boolean> =
    this.plannerSettings.getMultipleSuppliersSetting();
  supplierList$: Observable<Supplier[]> = this.plannerSettings
    .getSupplierList()
    .pipe(
      map((suppliers) => suppliers.sort((a, b) => (a.name > b.name ? 1 : -1)))
    );

  ngOnInit(): void {
    this.plannerService.getSuppliers();
    // console.log(this.isHandset);
  }

  onBackClick() {
    this.back.emit();
  }
  onSupplierSelectedChange(e: MatSelectChange) {
    this.plannerSettings.setShowDetailsSetting(false);
    if (Array.isArray(e.value)) {
      this.plannerSettings.setSelectedSuppliers(e.value);
      this.router.navigate(['planner', this.generateParams(e.value)]);
    } else {
      console.log(e.value);

      this.plannerSettings.setSelectedSuppliers([e.value]);
      this.router.navigate(['planner', 'supplierIDs=' + e.value]);
    }
  }

  onMultipleSuppliersSettingChanged(
    e: MatSlideToggleChange,
    selectedSupplier: string
  ) {
    this.plannerSettings.setSelectedSuppliers([selectedSupplier]);
    this.plannerSettings.setMultipleSuppliersSetting(e.checked);
    this.router.navigate(['planner', 'supplierIDs=' + selectedSupplier]);
  }

  private generateParams(selectedSupplierIds: string[]) {
    this.plannerSettings.setSelectedSuppliers(selectedSupplierIds);
    var params = '';
    for (let i = 0; i < selectedSupplierIds.length; i++) {
      params = params + 'supplierIDs=' + selectedSupplierIds[i];
      if (i < selectedSupplierIds.length - 1) {
        params = params + '&';
      }
    }
    return params;
  }
}
