<app-toolbar *ngIf="planners" (back)="planners = false"></app-toolbar>
<div class="buttons" *ngIf="!planners && !reports">
  <div class="links" *ngIf="loginDisplay$ | async">
    <button
      mat-raised-button
      [routerLink]="['/salesreports']"
      routerLinkActive="router-link-active"
    >
      Reports
    </button>
  </div>
  <ng-container *ngIf="!(loginDisplay$ | async)"
    ><button mat-raised-button (click)="login()">
      Please Click Here To Log In
    </button>
  </ng-container>
  <ng-container *ngIf="loginDisplay$ | async">
    <button mat-raised-button (click)="logout()">
      Please Click Here To Log Out
    </button>
  </ng-container>
</div>
<div class="image"></div>
