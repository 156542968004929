export interface SingleChartDataNode {
  name: string;
  value: any;
}
export interface MultiChartDataNode {
  value: any;
  name: string;
  series: SingleChartDataNode[];
}
export interface Option {
  name: string;
  id: string;
  units: string;
  unitsPositionBefore: boolean;
  isCalculated: boolean;
  calculationParameters: any[];
  calculationType: CalculationType[] | null;
  accumulationType: AccumulationType;
}

export interface Label {
  name: string;
  id: string;
  uniqueAcrossInvoice: boolean;
}
export enum AccumulationType {
  addition,
  average,
}
export enum CalculationType {
  addition,
  subtraction,
  multiplication,
  division,
}
