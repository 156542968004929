import { map, switchMap, tap } from 'rxjs/operators';
import { UserTableEntity } from './../../models/UserTableEntity';
import { AdminService } from './../../services/admin.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private adminService: AdminService
  ) {}
  loginDisplay$: Observable<boolean> = this.authService.getLoggedInStatus();
  user$: Observable<UserTableEntity> = this.adminService.getUsers().pipe(
    switchMap((users) => {
      return this.authService.getLoggedInUserName().pipe(
        tap((_) => console.log()),
        map(
          (name) =>
            users.find((user) => user.displayName == name) ?? {
              displayName: '',
              planners: false,
              salesReports: false,
              admin: false,
            }
        ),
        tap((_) => console.log())
      );
    })
  );
  planners: boolean = false;
  reports: boolean = false;
  users: UserTableEntity[] = [];

  ngOnInit(): void {
    this.user$.subscribe((_) => console.log(_));
  }
  logout() {
    this.authService.logout();
  }
  login() {
    this.authService.login();
  }
}
