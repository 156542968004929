import { BreakpointsService } from 'src/app/shared/services/breakpoints.service';
import { EndpointsService } from './../shared/services/endpoints.service';
import { PlannerExportService } from '../shared/services/planner-export.service';

import { PlannerProductData } from './../models/PlannerProductData';
import { PlannerStylesService } from './planner-styles.service';
import { PlannerSettingsService } from './planner-settings.service';
import { PlannerColumnData } from './../models/PlannerColumnData';
import { PlannerService } from './planner.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, combineLatest, of, iif, from } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  distinctUntilChanged,
  filter,
  groupBy,
  map,
  mergeMap,
  pairwise,
  share,
  switchMap,
  take,
  tap,
  toArray,
} from 'rxjs/operators';
import { PlannerStaticSettingsService } from './planner-static-settings.service';
import { CsvExportService } from '../shared/services/csv-export.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SalesDetailArgs } from '../models/SalesDetailArgs';

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss'],
})
export class PlannerComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatTable) table: MatTable<PlannerProductData> | null = null;

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  constructor(
    private plannerService: PlannerService,
    private route: ActivatedRoute,
    private plannerSettings: PlannerSettingsService,
    private plannerStaticSettings: PlannerStaticSettingsService,
    private plannerStyles: PlannerStylesService,
    private csv: PlannerExportService,
    private breakpoints: BreakpointsService
  ) {
    this.route.url.subscribe((params) => {
      if (params.length) {
        var ids: string[] = this.extractSupplierIdsFromParams(params[1].path);
        if (ids.length > 1) {
          this.plannerSettings.setMultipleSuppliersSetting(true);
        }
        this.plannerSettings.skipNextSettingsUpdate();

        this.plannerSettings.setSelectedSuppliers(ids);
        this.plannerSettings.loadSettings(ids[0]);

        this.plannerService.generatePlanner(params[1].path);
        // this.plannerService.getPlanner(params[1].path);
      }
    });
  }
  pageSize$: Observable<number> = this.plannerSettings.getPagination().pipe(
    map((pagination) => {
      if (pagination) {
        return Number.parseInt(pagination);
      } else {
        return 0;
      }
    })
  );
  dataSource = new MatTableDataSource<PlannerProductData>();
  salesDetailArgs$: Observable<SalesDetailArgs | null> =
    this.plannerSettings.getSalesDetailArgs();
  horizontalGridLines$: Observable<boolean> =
    this.plannerStyles.getHorizontalGridLinesSetting();
  verticalGridLines$: Observable<boolean> =
    this.plannerStyles.getVerticalGridLinesSetting();
  showDetails$: Observable<boolean> =
    this.plannerSettings.getShowDetailsSetting();
  plannerColumns$: Observable<PlannerColumnData[]> =
    this.plannerService.getPlannerColumns();
  plannerRows$: Observable<PlannerProductData[]> =
    this.plannerService.getPlannerRows();
  currentFilter$: Observable<string> = this.plannerSettings.getCurrentFilter();
  currentExclusion$: Observable<string> =
    this.plannerSettings.getCurrentExclusion();
  groupBy$: Observable<string> = this.plannerSettings.getGroupById();
  showTotals$: Observable<string> = this.plannerSettings.getShowTotals();
  showTotals: string = '';
  fromYearMonth$: Observable<string> =
    this.plannerSettings.getFromYearMonthSetting();
  allYearMonths$: Observable<string[]> =
    this.plannerSettings.getAllYearMonths();
  displayedColumns$: Observable<string[]> = combineLatest([
    this.plannerSettings.getDisplayedColumns().pipe(distinctUntilChanged()),
    this.plannerSettings.getHiddenColumnTypes().pipe(distinctUntilChanged()),
    this.fromYearMonth$.pipe(distinctUntilChanged()),
  ]).pipe(
    distinctUntilChanged(),
    map(([displayedColumns, hiddenColumnTypes, fromYearMonth]) => {
      let cols = displayedColumns.filter((displayedColumn) => {
        return (
          !hiddenColumnTypes.includes(displayedColumn.split('_')[0]) &&
          (this.isDetailsColumn(displayedColumn) ||
            fromYearMonth == '' ||
            displayedColumn.split('_')[1] >= fromYearMonth)
        );
      });
      this.width = cols.length;
      return cols;
    }),
    share()
  );

  subTotalColumns$: Observable<string[]> = this.displayedColumns$.pipe(
    map((columns) => columns.map((column) => column + '_SubTotal'))
  );

  hiddenColumnTypes$: Observable<string[]> =
    this.plannerSettings.getHiddenColumnTypes();
  lastColumnMonth: number = 0;

  sortedData: PlannerProductData[] = [];
  width: number = 0;
  ngOnInit() {
    this.plannerSettings.configure();
    this.plannerStyles.loadStyles();
    this.plannerStyles.configure();
    this.plannerSettings.getCurrentFilter().subscribe();
    this.plannerSettings.getCurrentExclusion().subscribe();

    this.showTotals$.subscribe((showTotals) => {
      this.showTotals = showTotals;
    });
    combineLatest(this.currentFilter$, this.currentExclusion$).subscribe(
      ([currentFilter, currentExclusion]) => {
        if (currentFilter != null && currentExclusion != null) {
          this.dataSource.filter =
            currentFilter.trim().toLowerCase() +
            '<>' +
            currentExclusion.trim().toLowerCase();
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.sortingDataAccessor = (product, sortHeaderId) =>
      this.sortingDataAccessor(product, sortHeaderId);
    this.dataSource.filterPredicate = (product, filter) =>
      this.filterPredicate(product, filter);

    this.dataSource.sort = this.sort;
    this.pageSize$.subscribe((paginator) => {
      if (paginator) {
        this.dataSource.paginator = this.paginator;
      }
    });

    this.sort.disableClear = true;
    this.sort.direction = 'asc';
    this.dataSource
      .connect()
      .pipe(
        tap((renderedData) => {
          this.csv.setRenderedData(renderedData);
        })
      )
      .subscribe((renderedData) => (this.sortedData = [...renderedData]));
    this.plannerRows$.subscribe((data) => {
      this.dataSource.data = data;
      this.sort.sort({
        disableClear: true,
        id: 'SKU_ProductDetails',
        start: 'asc',
      });
      setTimeout(() => {
        this.onTableRenderRequired();
      }, 400);
    });
    this.groupBy$.subscribe((groupBy) => {
      this.groupBy = groupBy;

      this.sort.sort({
        disableClear: true,
        id: 'SKU_ProductDetails',
        start: 'asc',
      });
      setTimeout(() => {
        this.onTableRenderRequired();
      }, 400);
    });
  }

  onTableRenderRequired() {
    this.table?.updateStickyColumnStyles();
    this.table?.renderRows();
  }
  onSortChange(e: any) {
    console.log(e);
  }
  canShowDetails(column: string) {
    return this.plannerStaticSettings.columnsThatCanShowDetails.includes(
      column.split('_')[0]
    );
  }
  isLastRow(row: PlannerProductData) {
    const sortedData = this.sortedData;
    if (sortedData[sortedData.length - 1]?.id == row?.id) {
      return row.plannerCellDataArray.find((cell) => cell.id == this.groupBy)
        ?.text;
    } else {
      return '';
    }
  }
  isFirstRowGroupHeading(row: PlannerProductData) {
    if (this.sortedData[0]?.id == row.id) {
      return row.plannerCellDataArray.find((cell) => cell.id == this.groupBy)
        ?.text;
    } else {
      return '';
    }
  }
  nextRowIsDifferentGroup(row: PlannerProductData) {
    const sortedData = this.sortedData;
    const rowIndex = sortedData.findIndex(
      (sortedRow) => row.id == sortedRow.id
    );
    if (sortedData[rowIndex + 1]) {
      return sortedData[rowIndex].plannerCellDataArray.find(
        (cell) => cell.id == this.groupBy
      )?.text ==
        sortedData[rowIndex + 1].plannerCellDataArray.find(
          (cell) => cell.id == this.groupBy
        )?.text
        ? ''
        : sortedData[rowIndex + 1].plannerCellDataArray.find(
            (cell) => cell.id == this.groupBy
          )?.text;
    }
    return '';
  }

  isNewMonth(col: PlannerColumnData) {
    if (!this.isDetailsColumn(col.id)) {
      var colMonth = new Date(col.date).getMonth();
      if (
        colMonth > this.lastColumnMonth ||
        (this.lastColumnMonth == 11 && colMonth == 0)
      ) {
        this.lastColumnMonth = colMonth;
        return true;
      }
    } else {
      this.lastColumnMonth = -1;
    }
    return false;
  }
  getSubTotal(columnId: string, row: PlannerProductData): [number, number] {
    const { quantity, value } = this.dataSource.filteredData
      .filter(
        (data) =>
          data.plannerCellDataArray.find((cell) => cell.id == this.groupBy)
            ?.text ==
          row.plannerCellDataArray.find((cell) => cell.id == this.groupBy)?.text
      )
      .reduce(
        ({ quantity, value }, row) => {
          let cell = row.plannerCellDataArray.find(
            (cell) => cell.id == columnId
          );
          return {
            quantity: quantity + (cell?.qty ?? 0),
            value: value + (cell?.value ?? 0),
          };
        },
        { quantity: 0, value: 0 }
      );
    return [quantity, value];
  }
  getTotal(columnId: string): [number, number] {
    const { quantity, value } = this.dataSource.filteredData.reduce(
      ({ quantity, value }, row) => {
        let cell = row.plannerCellDataArray.find((cell) => cell.id == columnId);
        return {
          quantity: quantity + (cell?.qty ?? 0),
          value: value + (cell?.value ?? 0),
        };
      },
      { quantity: 0, value: 0 }
    );
    return [quantity, value];
  }

  isDetailsColumn(columnId: string) {
    const str = columnId;
    return str.split('_')[1] == 'ProductDetails';
  }

  onRemovePagination() {
    this.dataSource.paginator = null;
    this.plannerSettings.setPagination('');
  }
  onPaginate() {
    this.plannerSettings.setPagination('20');
  }
  setPagintion(event: PageEvent) {
    this.plannerSettings.setPagination(event.pageSize.toString());
  }
  private extractSupplierIdsFromParams(params: string) {
    return params.replace(new RegExp('supplierIDs=', 'g'), '').split('&');
  }
  //columnId to group by
  groupBy: string = '';
  private sortingDataAccessor(
    product: PlannerProductData,
    sortHeaderId: string
  ) {
    if (sortHeaderId.indexOf('Product') != -1) {
      let cell = product.plannerCellDataArray.find(
        (cell) => cell.id == sortHeaderId
      );

      let text = cell?.text ?? '';
      if (this.groupBy) {
        let groupValue =
          product.plannerCellDataArray.find((cell) => cell.id == this.groupBy)
            ?.text ?? '';
        text = groupValue + text;
      }
      return text;
    } else {
      let qty = product.plannerCellDataArray.find(
        (cell) => cell.id == sortHeaderId
      )?.qty;
      if (qty != undefined) {
        return +qty?.valueOf() ?? 0;
      } else return 0;
    }
  }

  getBottom() {
    if (this.breakpoints.isDevice()) {
      return '17px';
    } else {
      return '24px';
    }
  }
  getTableMarginBottom() {
    if (this.breakpoints.isHandsetPortrait()) {
      return '112px';
    } else {
      return '56px';
    }
  }
  private filterPredicate(
    product: PlannerProductData,
    filterExclusionValue: string
  ) {
    let passFilterCheck = false;
    let passExclusionCheck = false;
    let [filter, exclusion] = filterExclusionValue.split('<>');
    if (filter[0] == '~') {
      const [columnDef, filterValue] = filter.split(':');
      if (filterValue) {
        let filterValues: string[] = filterValue.split(',');
        let allFiltersPassed: boolean[] = [];
        const cell = product.plannerCellDataArray.find(
          (cell) =>
            cell.id ==
            `${columnDef.replace('~', '').toUpperCase()}_ProductDetails`
        );
        for (const value of filterValues) {
          allFiltersPassed.push(
            cell?.text
              ?.toString()
              .toLowerCase()
              .includes(value.toLowerCase()) ?? false
          );
        }
        passFilterCheck = allFiltersPassed.some((pass) => pass);
      } else {
        passFilterCheck = true;
      }
    } else {
      const sku =
        product.plannerCellDataArray.find(
          (cell) => cell.id == 'SKU_ProductDetails'
        )?.text ?? '';
      const name =
        product.plannerCellDataArray.find(
          (cell) => cell.id == 'NAME_ProductDetails'
        )?.text ?? '';
      const category =
        product.plannerCellDataArray.find(
          (cell) => cell.id == 'CATEGORY_ProductDetails'
        )?.text ?? '';

      passFilterCheck =
        name.toString().indexOf(filter) != -1 ||
        category.toString().indexOf(filter) != -1 ||
        sku.toString().indexOf(filter) != -1;
    }
    if (exclusion != '') {
      if (exclusion[0] == '~') {
        const [columnDef, exclusionValue] = exclusion.split(':');
        if (exclusionValue) {
          let exclusionValues: string[] = exclusionValue.split(',');
          let someExclusionsPassed: boolean[] = [];
          const cell = product.plannerCellDataArray.find(
            (cell) =>
              cell.id ==
              `${columnDef.replace('~', '').toUpperCase()}_ProductDetails`
          );
          for (const value of exclusionValues) {
            someExclusionsPassed.push(
              cell?.text
                ?.toString()
                .toLowerCase()
                .indexOf(value.toLowerCase()) == -1 ?? true
            );
          }
          passExclusionCheck = someExclusionsPassed.every((pass) => pass);
        } else {
          passExclusionCheck = true;
        }
      } else {
        const sku =
          product.plannerCellDataArray.find(
            (cell) => cell.id == 'SKU_ProductDetails'
          )?.text ?? '';
        const name =
          product.plannerCellDataArray.find(
            (cell) => cell.id == 'NAME_ProductDetails'
          )?.text ?? '';
        const category =
          product.plannerCellDataArray.find(
            (cell) => cell.id == 'CATEGORY_ProductDetails'
          )?.text ?? '';
        passExclusionCheck =
          name.toString().indexOf(exclusion) == -1 &&
          category.toString().indexOf(exclusion) == -1 &&
          sku.toString().indexOf(exclusion) == -1;
      }
    } else {
      passExclusionCheck = true;
    }

    return passFilterCheck && passExclusionCheck;
  }
}
