import { switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { EndpointsService } from './endpoints.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserTableEntity } from '../models/UserTableEntity';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient, private endpoints: EndpointsService) {
    this.users$
      .pipe(
        switchMap((users) =>
          this.http.post(this.endpoints.getUrl('Users'), users)
        )
      )
      .subscribe();
  }
  private _users: BehaviorSubject<UserTableEntity[]> = new BehaviorSubject<
    UserTableEntity[]
  >([]);
  users$: Observable<UserTableEntity[]> = this._users.asObservable();
  setUsers(users: UserTableEntity[]) {
    this._users.next(users);
  }

  getUsers() {
    return this.http.get<UserTableEntity[]>(this.endpoints.getUrl('Users'));
  }
}
