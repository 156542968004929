<app-toolbar></app-toolbar>
<!-- <router-outlet *ngIf="showDetails$ | async"></router-outlet> -->

<ng-container>
  <div
    style="width: 100vw; height: 3.6rem"
    *ngIf="!(showDetails$ | async)"
  ></div>

  <!-- settings -->
  <app-settings-bar
    (tableRenderRequired)="onTableRenderRequired()"
    *ngIf="(plannerRows$ | async)?.length && !(showDetails$ | async)"
  ></app-settings-bar>
  <div id="print-section" class="wrapper">
    <!-- table -->
    <div class="hidden-detail" *ngIf="showDetails$ | async">
      <app-sales-detail></app-sales-detail>
    </div>

    <div class="detail" *ngIf="showDetails$ | async">
      <app-sales-detail></app-sales-detail>
    </div>
    <table
      [ngStyle]="{ 'margin-bottom': getTableMarginBottom() }"
      class="table"
      mat-table
      [dataSource]="dataSource"
      matSort
      multiTemplateDataRows
      (sortChange)="onSortChange($event)"
    >
      <!-- column definitions -->
      <ng-container
        *ngFor="let column of plannerColumns$ | async"
        [matColumnDef]="column.id"
        [sticky]="column.isSticky"
        [stickyEnd]="column.id == 'onOrder_210001' || column.id == 'AL_209902'"
      >
        <!-- header -->
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.color]="column.color"
          mat-sort-header
          [ngClass]="{
            'vertical-grid-lines': verticalGridLines$ | async,
            'horizontal-grid-lines': horizontalGridLines$ | async,
            'thick-left-boarder':
              isNewMonth(column) || column.id == 'AL_209902',
            'text-left-padded': isDetailsColumn(column.id),
            'is-sticky': column.isSticky
          }"
        >
          <app-planner-header-cell [column]="column"></app-planner-header-cell>
        </th>
        <!-- cells -->
        <td
          [ngClass]="{
            'vertical-grid-lines': verticalGridLines$ | async,
            'horizontal-grid-lines': horizontalGridLines$ | async,
            'thick-left-boarder':
              isNewMonth(column) || column.id == 'AL_209902',
            'text-left-padded': isDetailsColumn(column.id)
          }"
          mat-cell
          *matCellDef="let productData"
          [style.color]="column.color"
        >
          <app-planner-cell
            [columnId]="column.id"
            [productData]="productData"
          ></app-planner-cell>
        </td>

        <td
          mat-footer-cell
          *matFooterCellDef
          class="thick-top-border"
          [ngClass]="{
            'vertical-grid-lines': verticalGridLines$ | async,
            'horizontal-grid-lines': horizontalGridLines$ | async,
            'thick-left-boarder':
              isNewMonth(column) || column.id == 'AL_209902',
            'text-left-padded': isDetailsColumn(column.id)
          }"
        >
          <ng-container *ngIf="showTotals">
            <p *ngIf="column.id == 'NAME_ProductDetails'">
              <strong>Total</strong>
            </p>
            <app-planner-footer-cell
              [total]="getTotal(column.id)"
            ></app-planner-footer-cell>
          </ng-container>
        </td>
      </ng-container>
      <!-- subtotals -->
      <ng-container
        *ngFor="let column of plannerColumns$ | async"
        [matColumnDef]="column.id + '_SubTotal'"
        [sticky]="column.isSticky"
        [stickyEnd]="column.id == 'onOrder_210001'"
        ><td
          class="sub-total-cell"
          [ngClass]="{
            'vertical-grid-lines': verticalGridLines$ | async,
            'horizontal-grid-lines': horizontalGridLines$ | async,
            'thick-left-boarder': isNewMonth(column),
            'text-left-padded': isDetailsColumn(column.id)
          }"
          mat-cell
          *matCellDef="let productData"
          [style.color]="column.color"
        >
          <ng-container
            *ngIf="
              (nextRowIsDifferentGroup(productData) ||
                isLastRow(productData)) &&
              groupBy &&
              showTotals
            "
          >
            <p *ngIf="column.id == 'NAME_ProductDetails'">
              <strong>SubTotal</strong>
            </p>
            <app-planner-footer-cell
              [total]="getSubTotal(column.id, productData)"
            ></app-planner-footer-cell>
          </ng-container>
        </td>
      </ng-container>

      <!-- groupHeading columns -->
      <ng-container matColumnDef="firstGroupHeader">
        <td mat-cell *matCellDef="let row" [attr.colspan]="width">
          <div
            class="group-header"
            *ngIf="isFirstRowGroupHeading(row) && groupBy"
          >
            <h4>{{ isFirstRowGroupHeading(row) }}</h4>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="groupSubTotal">
        <td mat-cell *matCellDef="let row">
          <div
            class="group-header"
            *ngIf="nextRowIsDifferentGroup(row) && groupBy"
          >
            <h4>{{ nextRowIsDifferentGroup(row) }}</h4>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="groupHeader">
        <td mat-cell *matCellDef="let row" [attr.colspan]="width">
          <div
            class="group-header"
            *ngIf="nextRowIsDifferentGroup(row) && groupBy"
          >
            <h4>{{ nextRowIsDifferentGroup(row) }}</h4>
          </div>
        </td>
      </ng-container>
      <!-- row definitions -->
      <!-- headerRow -->
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns$ | async; sticky: true"
      ></tr>
      <!-- rows -->
      <tr
        mat-row
        *matRowDef="let row; columns: ['firstGroupHeader']"
        class="group-header-row"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns$ | async"
        class="row"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: subTotalColumns$ | async"
        class="sub-totals-row"
      ></tr>

      <tr
        mat-row
        *matRowDef="let row; columns: ['groupHeader']"
        class="group-header-row"
      ></tr>

      <tr mat-footer-row *matFooterRowDef="displayedColumns$ | async"></tr>
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td
          class="mat-cell"
          [attr.colspan]="(displayedColumns$ | async)?.length"
          style="text-align: left"
        >
          <app-planner-no-data-row></app-planner-no-data-row>
        </td>
      </tr>
    </table>
    <div class="paginator">
      <button
        *ngIf="pageSize$ | async"
        mat-button
        (click)="onRemovePagination()"
      >
        Remove Paginator
      </button>
      <mat-paginator
        *ngIf="pageSize$ | async"
        [ngStyle]="{ bottom: getBottom() }"
        [pageSize]="pageSize$ | async"
        [pageSizeOptions]="[10, 20, 50, 100]"
        (page)="setPagintion($event)"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
      <button *ngIf="!(pageSize$ | async)" mat-button (click)="onPaginate()">
        Paginate
      </button>
    </div>
  </div>
</ng-container>
