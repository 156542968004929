import { PlannerSettingsService } from './../planner-settings.service';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-planner-footer-cell',
  templateUrl: './planner-footer-cell.component.html',
  styleUrls: ['./planner-footer-cell.component.scss'],
})
export class PlannerFooterCellComponent implements OnInit {
  @Input() columnId: string = '';
  @Input() total: [number, number] = [0, 0];
  constructor(private plannerSettings: PlannerSettingsService) {}
  showVal$: Observable<boolean> = this.plannerSettings.showVal();
  showQty$: Observable<boolean> = this.plannerSettings.showQty();
  ngOnInit(): void {}
}
