import { SettingsService } from './../../services/settings.service';
import { DateRange, NamedDateRange } from '../../models/DateRange';
import { SalesService } from './../../../sales/sales.service';
import { Partition } from './../../models/Partition';
import { DataService } from './../../services/data.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BreakpointsService } from 'src/app/shared/services/breakpoints.service';
import { PartitionType } from '../../models/PartitionType';
import {
  map,
  startWith,
  skip,
  debounceTime,
  tap,
  take,
  distinctUntilChanged,
  shareReplay,
  filter,
} from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from '../../helpers/format-datepicker';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NamedSalesReportSettingsTableEntity } from '../../models/SalesReportSettingsTableEntity';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class NavigationComponent implements OnInit {
  @Output() navigate: EventEmitter<void> = new EventEmitter<void>();
  constructor(
    private breakpoints: BreakpointsService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private settings: SettingsService
  ) {}
  handsetPortrait$: Observable<boolean> = this.breakpoints.handsetPortrait$;

  //partitions
  partitionTypes$: Observable<PartitionType[]> =
    this.dataService.getPartitionTypes();
  expandedPartitionType: string = '';
  selectedPartition: string = '';
  options: Partition[] = [];
  partitionFilter = new FormControl();
  filteredOptions: Observable<Partition[]> =
    this.partitionFilter.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      map((value) => this._filter(value))
    );

  //dateGrouping
  dateGrouping$: Observable<string> = this.settings.getDateGrouping();
  onDateGroupingChange(e: MatButtonToggleChange) {
    this.settings.setDateGrouping(e.value);
  }
  //date range
  namedDateRanges: NamedDateRange[] = this.dataService.getNamedDateRanges();

  dateRange = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date()),
  });

  //multiplue partitions setting
  multiplePartitions: boolean = false;
  selectedPartitions = new SelectionModel<string>(
    true, // multiple selection or not
    [] // initial selected values
  );

  //named settings
  namedSettings$: Observable<NamedSalesReportSettingsTableEntity[]> =
    this.settings.getNamedSettings();
  selectedNamedDateRange: NamedDateRange = this.namedDateRanges[0];
  ngOnInit(): void {
    this.settings.configure();
    this.settings.getNamedDateRange().subscribe((dateRangeName) => {
      if (dateRangeName != this.selectedNamedDateRange.name) {
        this.selectedNamedDateRange =
          this.namedDateRanges.find(
            (namedDateRange) => namedDateRange.name == dateRangeName
          ) ?? this.namedDateRanges[0];
        this.dateRange.setValue({
          start:
            this.namedDateRanges.find(
              (namedDateRange) => namedDateRange.name == dateRangeName
            )?.dateRange.start ?? this.namedDateRanges[0].dateRange.start,
          end:
            this.namedDateRanges.find(
              (namedDateRange) => namedDateRange.name == dateRangeName
            )?.dateRange.end ?? this.namedDateRanges[0].dateRange.end,
        });
      }
    });

    this.route.queryParams.pipe(skip(1)).subscribe((params) => {
      if (params?.groupBy == 'All') {
        this.expandedPartitionType = '';
      } else {
        this.expandedPartitionType = params?.groupBy ?? '';
      }
      this.selectedPartition = params?.selectedPartition;
    });
    this.partitionTypes$.pipe(skip(1)).subscribe((partitionTypes) => {
      if (this.expandedPartitionType) {
        this.setOptions(
          partitionTypes.find(
            (partitionType) => partitionType.name == this.expandedPartitionType
          )?.partitions ?? []
        );
      }
    });
    this.dateRange.valueChanges
      .pipe(skip(1), shareReplay(1), distinctUntilChanged())
      .subscribe((dateRange: DateRange) => {
        if (dateRange.end) {
          dateRange.end?.setHours(23, 59, 59);
        }
        this.settings.setDateRange(dateRange);
      });
  }
  setOptions(partitions: Partition[]) {
    this.options = partitions;
  }
  onNavigate() {
    this.navigate.emit();
  }
  onNamedSettingClick(namedSetting: NamedSalesReportSettingsTableEntity) {
    this.settings.applyNamedSetting(namedSetting);
    this.router.navigate(['/salesreports'], {
      queryParams: {
        groupBy: this.removeSpaces(namedSetting.partitionType),
        ids: namedSetting.ids,
      },
    });
  }
  isExpanded(partitionType: PartitionType) {
    return this.expandedPartitionType == partitionType.name.replace(' ', '');
  }
  toggleExpandPartitionType(partitionType: PartitionType) {
    if (this.expandedPartitionType == this.removeSpaces(partitionType.name)) {
      this.expandedPartitionType = '';
      this.selectedPartitions.clear();
    } else {
      this.expandedPartitionType = this.removeSpaces(partitionType.name);
      this.setOptions(partitionType.partitions);
    }
  }
  getNamedDateRangeName() {
    if (
      this.selectedNamedDateRange.dateRange.start?.getTime() ==
        this.dateRange.get('start')?.value.getTime() &&
      this.selectedNamedDateRange.dateRange.end?.getTime() ==
        this.dateRange.get('end')?.value.getTime()
    ) {
      return this.selectedNamedDateRange;
    }
    return this.namedDateRanges[this.namedDateRanges.length - 1];
  }
  setNamedDateRange(e: NamedDateRange) {
    this.dateRange.setValue({ ...e.dateRange });

    this.settings.setNamedDateRange(e.name);
  }

  onMultiplePartitionsToggleChange(e: MatSlideToggleChange) {
    this.multiplePartitions = e.checked;
  }
  onMultiplePartitionsSelectionChange(e: MatCheckboxChange) {
    this.selectedPartitions.toggle(this.removeSpaces(e.source.value));
  }
  onSelectedPartitionsGoClick() {
    let selectedPartitions = this.selectedPartitions.selected;
    this.selectedPartitions.clear();
    this.router.navigate(['/salesreports'], {
      queryParams: {
        groupBy: this.removeSpaces(this.expandedPartitionType),
        ids: selectedPartitions,
      },
    });
  }
  removeSpaces(str: string) {
    return str.replace(new RegExp(' ', 'g'), '');
  }
  private _filter(value: string): Partition[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
}
