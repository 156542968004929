<div *ngIf="column.id && !(dataFetchInProgress$ | async)">
  <ng-container *ngIf="show">
    <h4>{{ column.status }}</h4>
    <h4>{{ column.containerNumber }}</h4>
    <h4>{{ column.bolDate | date: column.dateFormat }}</h4>
  </ng-container>
  <h4>{{ column.date | date: column.dateFormat }}</h4>
  <h4>
    <ng-container *ngIf="column.link">
      <a mat-button [href]="column.link" target="_blank">{{ column.name }}</a>
    </ng-container>
    <ng-container *ngIf="!column.link">{{ column.name }}</ng-container>
  </h4>
</div>
