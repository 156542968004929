import { BreakpointsService } from 'src/app/shared/services/breakpoints.service';
import { PlannerExportService } from './../../shared/services/planner-export.service';
import { PlannerProductData } from './../../models/PlannerProductData';
import { PlannerCellData } from './../../models/PlannerCellData';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSelectChange } from '@angular/material/select';
import { PlannerStaticSettingsService } from './../planner-static-settings.service';
import { PlannerSettingsService } from './../planner-settings.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MatCheckboxChange } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
// import { Options } from 'angular2-csv';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { PlannerService } from '../planner.service';
import { MatRadioChange } from '@angular/material/radio';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatInput } from '@angular/material/input';
@Component({
  selector: 'app-settings-bar',
  templateUrl: './settings-bar.component.html',
  styleUrls: ['./settings-bar.component.scss'],
})
export class SettingsBarComponent implements OnInit {
  constructor(
    private plannerService: PlannerService,
    private plannerSettings: PlannerSettingsService,
    private plannerStaticSettings: PlannerStaticSettingsService,
    private csvExportService: PlannerExportService,
    private breakpoints: BreakpointsService
  ) {}
  @Output() tableRenderRequired: EventEmitter<void> = new EventEmitter<void>();
  // csvExportOptions: Options = this.csvExportService.getCsvExportOptions(
  //   'plannerexport.csv',
  //   [],
  //   []
  // );
  expanded: boolean = !this.breakpoints.isDevice();
  allYearMonths$: Observable<string[]> =
    this.plannerSettings.getAllYearMonths();
  fromYearMonth$: Observable<string> =
    this.plannerSettings.getFromYearMonthSetting();
  data: PlannerProductData[] = [];
  allColumns: string[] = [];
  plannerColumns$: Observable<string[]> = this.plannerSettings
    .getAllColumns()
    .pipe(tap((cols) => (this.allColumns = cols)));

  displayedColumns$: Observable<string[]> =
    this.plannerSettings.getDisplayedColumns();
  hiddenColumns$: Observable<string[]> =
    this.plannerSettings.getHiddenColumns();

  plannerColumnTypes$: Observable<string[]> =
    this.plannerStaticSettings.getAllColumnTypes();
  hiddenColumnTypes$: Observable<string[]> =
    this.plannerSettings.getHiddenColumnTypes();
  showVal$: Observable<boolean> = this.plannerSettings.showVal();
  showQty$: Observable<boolean> = this.plannerSettings.showQty();
  // updatesAvailable$: Observable<boolean> = this.plannerSettings.getUpdatesAvailableSetting();
  groupById$: Observable<string> = this.plannerSettings.getGroupById();
  showTotals$: Observable<boolean> = this.plannerSettings
    .getShowTotals()
    .pipe(map((st) => st != ''));
  renderedData$: Observable<PlannerProductData[]> = this.csvExportService
    .getRenderedData()
    .pipe(distinctUntilChanged());
  exportData: any[] = [];
  selectedSuppliers: string = '';

  ngOnInit(): void {
    // this.plannerService.getSelectedSuppliers().subscribe(selectedSuppliers => this.selectedSuppliers = this.generateParams(selectedSuppliers.map(supplier => supplier.id)));
    this.displayedColumns$.subscribe((displayedColumns) => {
      // this.csvExportOptions.headers = displayedColumns;
      // this.csvExportOptions.keys = displayedColumns;
    });
    this.hiddenColumns$.subscribe((hiddenColumns) => {
      this.columnSelection.clear();
      this.columnSelection.select(...hiddenColumns);
    });
    this.hiddenColumnTypes$.subscribe((hiddenColumnTypes) => {
      this.columnTypeSelection.clear();
      this.columnTypeSelection.select(...hiddenColumnTypes);
    });
    this.renderedData$.subscribe((data) => {
      let exportData: any[] = [];
      const headers = data[0]?.plannerCellDataArray.map((cell) => cell.id);
      // exportData.push(headers);

      for (const row of data) {
        let rrr: any = {};
        for (let i = 0; i < row.plannerCellDataArray.length; i++) {
          const key = row.plannerCellDataArray[i].id;
          let cellData: any = '';
          if (row.plannerCellDataArray[i].text) {
            cellData = row.plannerCellDataArray[i].text;
          } else if (row.plannerCellDataArray[i].qty) {
            cellData = row.plannerCellDataArray[i].qty;
          } else if (row.plannerCellDataArray[i].value) {
            cellData = row.plannerCellDataArray[i].value;
          }
          rrr[key] = cellData;
        }
        exportData.push(rrr);
      }
      this.exportData = exportData;
    });
  }
  onDisplayFromChange(e: string) {
    this.plannerSettings.setFromYearMonthSetting(e);
  }
  //Column Selection -- both type and column
  private filterBeforeOpeningColumnSelector: string = '';
  private exclusionBeforeOpeningColumnSelector: string = '';
  onOpenColumnSelectorClick(filter: string, exclusion: string) {
    this.filterBeforeOpeningColumnSelector = filter;
    this.exclusionBeforeOpeningColumnSelector = exclusion;
    this.plannerSettings.setCurrentFilter('Selecting Visible Columns');
    this.plannerSettings.setCurrentExclusion('Selecting Visible Columns');
  }
  onColumnSelectorClosed() {
    setTimeout(() => {
      this.tableRenderRequired.emit();
    }, 500);
    this.plannerSettings.setCurrentFilter(
      this.filterBeforeOpeningColumnSelector
    );
    this.plannerSettings.setCurrentExclusion(
      this.exclusionBeforeOpeningColumnSelector
    );
  }

  onOpenRearrangeColumnSelectorDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.allColumns, event.previousIndex, event.currentIndex);
    this.plannerSettings.setAllColumns(this.allColumns);
    this.plannerSettings.setProductDetailsOrder(
      this.allColumns
        .filter((column) => column.includes('ProductDetails'))
        .map((column) => column.split('_')[0])
    );
  }
  //column selection
  columnSelection = new SelectionModel<string>(true, []);
  onColumnSelectionChange(e: MatCheckboxChange, columnName: string) {
    if (e.checked) {
      this.columnSelection.select(columnName);
      this.plannerSettings.hideColumn(columnName);
    } else {
      this.columnSelection.deselect(columnName);
      this.plannerSettings.unhideColumn(columnName);
    }
  }

  // column type
  columnTypeSelection = new SelectionModel<string>(true, []);
  onColumnTypeSelectionChange(e: MatCheckboxChange, columnTypeName: string) {
    if (e.checked) {
      this.columnTypeSelection.select(columnTypeName);
      this.plannerSettings.hideColumnType(columnTypeName);
    } else {
      this.columnTypeSelection.deselect(columnTypeName);
      this.plannerSettings.unhideColumnType(columnTypeName);
    }
  }

  //Filtering
  currentFilter$: Observable<string> = this.plannerSettings.getCurrentFilter();
  savedFilters$: Observable<string[]> = this.plannerSettings.getSavedFilters();
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.plannerSettings.setCurrentFilter(filterValue);
  }
  onSaveFilterClick(filter: string) {
    this.plannerSettings.addFilterToSavedFilters(filter);
  }
  onSelectSavedFilterClick(filter: string) {
    this.plannerSettings.setCurrentFilter(filter);
  }

  //groupBy
  onGroupByChange(e: MatSelectChange) {
    this.plannerSettings.setGroupById(e.value);
  }

  //Exclusions
  currentExclusion$: Observable<string> =
    this.plannerSettings.getCurrentExclusion();
  savedExclusions$: Observable<string[]> =
    this.plannerSettings.getSavedExclusions();
  applyExclusion(event: Event) {
    const exclusionValue = (event.target as HTMLInputElement).value;
    this.plannerSettings.setCurrentExclusion(exclusionValue);
  }
  onSaveExclusionClick(exclusion: string) {
    this.plannerSettings.addExclusionToSavedExclusions(exclusion);
  }
  onSelectSavedExclusionClick(exclusion: string) {
    this.plannerSettings.setCurrentExclusion(exclusion);
  }

  //cell display type
  onCellDisplayTypeChange(e: MatButtonToggleChange) {
    this.plannerSettings.setCellDisplayParameters(e.value);
  }

  //showTotals
  onShowTotalsChange(e: MatSlideToggleChange) {
    this.plannerSettings.setShowTotals(e.checked ? 'showTotals' : '');
  }
  getExportData() {
    let data: any[] = [];
    const headers = this.data[0]?.plannerCellDataArray.map((cell) => cell.id);
    // console.log(headers);
    data.push(headers);

    for (const row of this.data) {
      data.push(
        row.plannerCellDataArray.map(
          (cell) => cell.text ?? cell.value ?? cell.qty
        )
      );
    }
    return data;
  }

  // onRefreshClick(){
  //   this.plannerSettings.setUpdatesAvailableSetting(false);
  //   const selectedSuppliers = this.plannerService.getSelectedSuppliers()
  //   this.plannerService.getPlanner(this.selectedSuppliers);
  // }

  // private generateParams(selectedSupplierIds: string[]) {
  //   this.plannerService.setSelectedSuppliers(selectedSupplierIds);
  //   var params = '';
  //   for (let i = 0; i < selectedSupplierIds.length; i++) {
  //     params = params + 'supplierIDs=' + selectedSupplierIds[i];
  //     if (i < selectedSupplierIds.length - 1) {
  //       params = params + '&';
  //     }
  //   }
  //   return params;
  // }
}
