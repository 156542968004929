import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlannerStaticSettingsService {
  constructor() {}
  //general
  public stickyColumns = ['SKU', 'CODE', 'NAME', 'BRAND'];
  public readonly defaultProducDetailsOrder: string[] = [
    'BRAND',
    'CODE',
    'SKU',
    'NAME',
    'CATEGORY',
    'TAGS',
    'UNITS PER CARTON',
    'CARTONS PER PALLET',
    'WEIGHT',
    'LENGTH',
    'WIDTH',
    'HEIGHT',
    'UOM',
  ];
  public pastMonthsToShow = 3;
  public columnsThatCanShowDetails: string[] = ['SA', 'AGJ', 'AL'];
  public locationReverseOrder = [
    'Queensland',
    'Panayi',
    'Batch',
    'Seasonal Location',
    'VIC Office',
    'NSW Office',
    'S&D ',
    'Bidfood',
    'Viking',
    'MELBOURNE',
    'SYDNEY',
  ];
  //column Types
  private _allColumnTypes: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([
    'AGJ',
    'AL',
    'SA',
    'ST',
    'LOC',
    'CompletedPO',
    'ShippedPO',
    'OrderedPO',
    'POM',
  ]);
  //colours
  public colours: string[] = [
    '#FF1744',
    '#F50057',
    '#D500F9',
    '#651FFF',
    '#3D5AFE',
    '#2979FF',
    '#00B0FF',
    '#00E5FF',
    '#1DE9B6',
    '#00E676',
    '#76FF03',
    '#C6FF00',
    '#FFEA00',
    '#FFC400',
    '#FF9100',
    '#FF3D00',
    '#3E2723',
    '#263238',
    '#000000',
  ];
  private allColumnTypes$: Observable<string[]> =
    this._allColumnTypes.asObservable();
  getAllColumnTypes() {
    return this.allColumnTypes$;
  }
}
