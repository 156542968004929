import { BreakpointsService } from 'src/app/shared/services/breakpoints.service';
import { PlannerSettingsService } from './planner-settings.service';
import { Supplier } from './../models/Supplier';
import { ProductTableEntity } from './../models/ProductTableEntity';
import { PlannerColumnData } from './../models/PlannerColumnData';
import { PlannerProductData } from './../models/PlannerProductData';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';

import { DatePipe } from '@angular/common';
import {
  distinctUntilChanged,
  map,
  share,
  switchMap,
  tap,
} from 'rxjs/operators';
import { PlannerStaticSettingsService } from './planner-static-settings.service';
import { EndpointsService } from '../shared/services/endpoints.service';
import { AuthService } from '../shared/services/auth.service';
import {
  Order,
  PlannerCell,
  ProductDetails,
  PurchaseOrder,
} from '../models/Planner';

let product: ProductTableEntity;
export type productKey = keyof typeof product;
let productDetails: ProductDetails;
export type productDetailsKey = keyof typeof productDetails;

@Injectable({
  providedIn: 'root',
})
export class PlannerService {
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private endpoints: EndpointsService,
    private plannerSettings: PlannerSettingsService,
    private plannerStaticSettings: PlannerStaticSettingsService,
    private breakpoints: BreakpointsService,
    private authService: AuthService
  ) {
    this.authService
      .getLoggedInUserName()
      .subscribe((name) => (this.userName = name));
    this.plannerSettings
      .getProductDetailsOrder()
      .pipe(distinctUntilChanged())
      .subscribe();
  }
  private userName: string = '';

  //plannerColumns
  private _plannerColumns: BehaviorSubject<PlannerColumnData[]> =
    new BehaviorSubject<PlannerColumnData[]>([]);
  private plannerColumns$: Observable<PlannerColumnData[]> =
    this._plannerColumns.asObservable();
  getPlannerColumns(): Observable<PlannerColumnData[]> {
    return this.plannerColumns$.pipe(
      map((plannerColumns) =>
        plannerColumns.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        )
      ),
      tap((columns) => {
        this.plannerSettings.setAllColumns(columns.map((column) => column.id));
      })
    );
  }

  getOrderCustomers(orderIds: string[]) {
    return this.http.post<Order[]>(this.endpoints.getUrl('Orders'), {
      orderIds: orderIds,
    });
  }
  //New Service
  private _plannerCells: BehaviorSubject<PlannerCell[]> = new BehaviorSubject<
    PlannerCell[]
  >([]);
  plannerCells$: Observable<PlannerCell[]> = this._plannerCells.asObservable();
  private _productDetails: BehaviorSubject<ProductDetails[]> =
    new BehaviorSubject<ProductDetails[]>([]);
  productDetails$: Observable<ProductDetails[]> =
    this._productDetails.asObservable();
  private _purchaseOrders: BehaviorSubject<PurchaseOrder[]> =
    new BehaviorSubject<PurchaseOrder[]>([]);
  purchaseOrders$: Observable<PurchaseOrder[]> =
    this._purchaseOrders.asObservable();
  //plannerRows
  private _plannerRows: BehaviorSubject<PlannerProductData[]> =
    new BehaviorSubject<PlannerProductData[]>([]);
  private plannerRows$: Observable<PlannerProductData[]> =
    this._plannerRows.asObservable();
  getPlannerRows(): Observable<PlannerProductData[]> {
    return this.plannerRows$;
  }
  //http
  getSuppliers() {
    this.http
      .get<Supplier[]>(this.endpoints.getUrl('Suppliers'))
      .subscribe((suppliers) => {
        this.plannerSettings.setSupplierList(suppliers);
      });
  }
  //dataFetchinProgress
  private _dataFetchInProgress: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private dataFetchInProgress$: Observable<boolean> =
    this._dataFetchInProgress.asObservable();
  getDataFetchStatus() {
    return this.dataFetchInProgress$;
  }
  handsetProductDetails: string[] = ['SKU'];
  //productDetails: string[] = this.plannerStaticSettings.defaultProducDetailsOrder;

  //column utils
  private isValidProductDetailsKey(
    key: string,
    product: ProductDetails
  ): key is productDetailsKey {
    return true;
  }

  private generateNonPoColumn(
    name: string,
    thedate: Date,
    productDetails: boolean = false,
    dateFormat: string = '',
    isSticky: boolean = false
  ): PlannerColumnData {
    thedate.setHours(23);
    thedate.setMinutes(58);
    thedate.setSeconds(59);
    return {
      dateFormat: dateFormat,
      color: 'black',
      id: `${name}_${
        productDetails
          ? 'ProductDetails'
          : this.datePipe.transform(thedate, 'yyyyMM')
      }`,
      name: name,
      isSticky: isSticky,
      date: thedate,
      bolDate: undefined,
      status: undefined,
      containerNumber: undefined,
      link: '',
    };
  }
  private generatePomColumn(
    name: string,
    thedate: Date,
    productDetails: boolean = false,
    dateFormat: string = '',
    isSticky: boolean = false
  ): PlannerColumnData {
    thedate.setHours(23);
    thedate.setMinutes(57);
    thedate.setSeconds(59);
    return {
      dateFormat: dateFormat,
      color: 'brown',
      id: `${name}_${
        productDetails
          ? 'ProductDetails'
          : this.datePipe.transform(thedate, 'yyyyMM')
      }`,
      name: name,
      isSticky: isSticky,
      date: thedate,
      bolDate: undefined,
      status: undefined,
      containerNumber: undefined,
      link: '',
    };
  }
  private generateLOCColumn(
    name: string,
    thedate: Date,
    index: number
  ): PlannerColumnData {
    let newDate = new Date(thedate);
    newDate.setHours(23);
    newDate.setMinutes(59);
    newDate.setSeconds(59 - index);
    return {
      dateFormat: '',
      color: 'black',
      id: `LOC_${this.datePipe.transform(thedate, 'yyyyMM')}_${name}`,
      name: name.substr(0, 4),
      isSticky: false,
      date: newDate,
      bolDate: undefined,
      status: undefined,
      containerNumber: undefined,
      link: '',
    };
  }

  private generateColumns(startDate: Date, productDetails: string[]) {
    if (
      !productDetails ||
      productDetails.length == 0 ||
      (productDetails.length == 1 && productDetails[0] == '')
    ) {
      productDetails = this.plannerStaticSettings.defaultProducDetailsOrder;
    }
    let plannerColumns: PlannerColumnData[] = [];

    plannerColumns.push({
      dateFormat: '',
      color: 'black',
      id: `onOrder_210001`,
      name: 'On Order',
      isSticky: false,
      date: new Date(2100, 1, 1, 1, 1, 1, 1),
      bolDate: undefined,
      status: undefined,
      containerNumber: undefined,
      link: '',
    });
    // plannerColumns.push(
    //   this.generateNonPoColumn('AL', new Date(2099, 1, 1, 1, 1, 1, 1))
    // );
    //All product details columns

    for (let i = 0; i < productDetails.length; i++) {
      plannerColumns.push(
        this.generateNonPoColumn(
          productDetails[i],
          new Date(2000, 1, i + 1, 0, 0, 0, 0),
          true,
          '',
          this.plannerStaticSettings.stickyColumns.includes(productDetails[i]) //make sticky columns an editable option at some point
        )
      );
    }

    let allYearMonths: string[] = [];
    //All pom agj al sa and st columns
    for (let monthCounter = 0; monthCounter < 9; monthCounter++) {
      let date = new Date(startDate);
      // console.log(startDate.getMonth() + monthCounter);
      date.setDate(1);
      date.setMonth(startDate.getMonth() + monthCounter);
      // console.log(date);

      date.setDate(1);
      allYearMonths.push(this.datePipe.transform(date, 'yyyyMM') ?? '');
      let newDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      let AGJplannerColumn: PlannerColumnData = this.generateNonPoColumn(
        'AGJ',
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
      );
      let ALplannerColumn: PlannerColumnData = this.generateNonPoColumn(
        'AL',
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
      );
      let SAplannerColumn: PlannerColumnData = this.generateNonPoColumn(
        'SA',
        new Date(date.getFullYear(), date.getMonth() + 1, 0),
        false,
        'MM-yy'
      );
      let STplannerColumn: PlannerColumnData = this.generateNonPoColumn(
        'ST',
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
      );
      let POMplannerColumn: PlannerColumnData = this.generatePomColumn(
        'POM',
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
      );
      plannerColumns.push(POMplannerColumn);
      if (date <= new Date()) {
        plannerColumns.push(AGJplannerColumn);
      }
      let lastMonth = new Date();
      lastMonth.setMonth(new Date().getMonth() - 1);
      plannerColumns.push(ALplannerColumn);

      plannerColumns.push(SAplannerColumn);
      plannerColumns.push(STplannerColumn);
    }
    // console.log(plannerColumns);
    // console.log(allYearMonths);

    this.plannerSettings.setAllYearMonths(allYearMonths);
    return plannerColumns;
  }
  getPlannerCells(supplierIds: string) {
    return this.http.get<PlannerCell[]>(
      this.endpoints.getUrl('PlannerCells') + '?' + supplierIds
    );
  }
  getProductDetails(supplierIds: string) {
    return this.http.get<ProductDetails[]>(
      this.endpoints.getUrl('ProductDetails') + '?' + supplierIds
    );
  }
  getPurchaseOrders(supplierIds: string) {
    return this.http.get<PurchaseOrder[]>(
      this.endpoints.getUrl('PurchaseOrders') + '?' + supplierIds
    );
  }
  clearPlanner() {
    this._productDetails.next([]);
    this._plannerCells.next([]);
    this._purchaseOrders.next([]);
  }
  generatePlanner(supplierIds: string) {
    this._dataFetchInProgress.next(true);
    this.clearPlanner();
    this._plannerRows.next([]);
    this._plannerColumns.next([]);
    this.do(supplierIds)
      .pipe(
        switchMap((skus) => {
          this._dataFetchInProgress.next(false);
          return this.plannerSettings.getSupplierList().pipe(
            switchMap((suppliers) => {
              console.log(suppliers);
              let supplier = suppliers.find(
                (sup) => sup.id == supplierIds.split('=')[1]
              );
              if (supplier) {
                console.log(supplier);

                return this.http.get(
                  this.endpoints.getUrl('SavePlannerCellEntities') +
                    '?supplierId=' +
                    supplier.id +
                    '&supplierName=' +
                    supplier.name.substring(0, 5) +
                    '&skus=' +
                    skus.join(',')
                );
              }
              return of(false);
            })
          );
        })
        // switchMap(() => this.do(supplierIds))
      )
      .subscribe((_) => {
        console.log(_);

        console.log('finito');
      });
  }

  do(supplierIds: string) {
    let startDate = new Date();

    startDate.setMonth(
      startDate.getMonth() - this.plannerStaticSettings.pastMonthsToShow
    ); //make this editable via slider
    return this.plannerSettings
      .getSettings(this.userName, supplierIds.split('=')[1])
      .pipe(
        switchMap((settings) => {
          this._plannerColumns.next(
            this.generateColumns(startDate, settings.productDetailsOrder)
          );
          return forkJoin([
            this.getPlannerCells(supplierIds),
            this.getProductDetails(supplierIds),
            this.getPurchaseOrders(supplierIds),
          ]);
        })
      )
      .pipe(
        share(),
        map(([plannerCells, productDetails, purchaseOrders]) => {
          console.log('data recieved');
          const skus = [
            ...new Set(
              productDetails.map((product) => product.sku.substring(0, 3))
            ),
          ];

          console.log(plannerCells);
          console.log(productDetails);
          console.log(purchaseOrders);

          let stCells = plannerCells.filter((cell) => cell.type == 'ST');
          let alCells = plannerCells.filter((cell) => cell.type == 'AL');
          let saCells = plannerCells.filter((cell) => cell.type == 'SA');
          let pomCells = plannerCells.filter((cell) => cell.type == 'POM');
          let poCells = plannerCells.filter((cell) => cell.type == 'PO');
          let agjCells = plannerCells.filter((cell) => cell.type == 'AGJ');
          let onOrderCells = plannerCells.filter(
            (cell) =>
              cell.type == 'OnOrder' &&
              cell.yearMonth == this.datePipe.transform(new Date(), 'yyyyMM')
          );
          let locationsWithStock = new Set<string>(
            stCells.map((cell) => cell.label)
          );
          this._plannerCells.next(plannerCells);
          this._productDetails.next(productDetails);
          locationsWithStock.delete('');
          locationsWithStock.delete('Total');
          let plannerColumns = this._plannerColumns.getValue();
          for (let location of locationsWithStock) {
            plannerColumns.push(
              this.generateLOCColumn(
                location,
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() + 1,
                  0
                ),
                this.plannerStaticSettings.locationReverseOrder.indexOf(
                  location
                ) //make location reverse order an editable setting at some point
              )
            );
          }
          for (let purchase of purchaseOrders) {
            let startDate = new Date();
            startDate.setMonth(
              startDate.getMonth() - this.plannerStaticSettings.pastMonthsToShow
            );
            if (
              new Date(purchase.inStoreDate).getTime() > startDate.getTime()
            ) {
              plannerColumns.push({
                dateFormat: 'dd/MM/yy',
                color:
                  purchase.status == 'Completed'
                    ? 'red'
                    : purchase.status == 'Shipped'
                    ? 'BLUE'
                    : 'GREEN',
                id: `${purchase.status}PO_${this.datePipe.transform(
                  purchase.inStoreDate ?? new Date(),
                  'yyyyMM'
                )}_${purchase.orderNumber.split('-')[1]}`,
                name: purchase.orderNumber,
                isSticky: false,
                date: purchase.inStoreDate ?? new Date(),
                bolDate: purchase.bol ?? new Date(),
                status: purchase.status,
                containerNumber: purchase.containerNumber,
                link:
                  'https://inventory.dearsystems.com/Purchase#' + purchase.id,
              });
            }
          }

          this._plannerColumns.next(plannerColumns);
          let productsSeen: Set<string> = new Set<string>();
          let plannerRows: PlannerProductData[] = [];
          let plannerColumnIds = this._plannerColumns
            .getValue()
            .map((column) => column.id);
          for (let i = 0; i < productDetails.length; i++) {
            const product = productDetails[i];

            if (!productsSeen.has(product.id)) {
              const onOrderSku = onOrderCells.filter(
                (cell) => cell.productId == product.id
              );
              productsSeen.add(product.id);
              let plannerRow: PlannerProductData = {
                id: product.id,
                name: product.name,
                sku: product.sku,
                category: product.category,
                onOrder: 0,
                // onOrderSku.reduce((a, b) => {
                //   return a + b.quantity;
                // }, 0),
                plannerCellDataArray: [],
              };
              const stSku = stCells.filter(
                (cell) => cell.productId == product.id
              );
              const agjSku = agjCells.filter(
                (cell) => cell.productId == product.id
              );
              const alSku = alCells.filter(
                (cell) => cell.productId == product.id
              );
              const saSku = saCells.filter(
                (cell) => cell.productId == product.id
              );
              const pomSku = pomCells.filter(
                (cell) => cell.productId == product.id
              );
              const poSku = poCells.filter(
                (cell) => cell.productId == product.id
              );

              for (let y = 0; y < plannerColumnIds.length; y++) {
                const plannerColumnId = plannerColumnIds[y];
                const [columnType, columnIdentifier, location] =
                  plannerColumnId.split('_');
                plannerRow.plannerCellDataArray.push({
                  id: plannerColumnId,
                  note: '',
                  value: 0,
                  text: '',
                  qty: 0,
                  ids: '',
                });
                //set product details
                let t = columnType.toLowerCase();
                if (t == 'units per carton') {
                  t = 'innerCartonQuantity';
                } else if (t == 'cartons per pallet') {
                  t = 'cartonsPerPallet';
                }
                if (
                  columnIdentifier == 'ProductDetails' &&
                  this.isValidProductDetailsKey(t, product)
                ) {
                  plannerRow.plannerCellDataArray[y].text =
                    product[t]?.toString() ?? '';
                }
                ////////////////////////////////////////////////////////////
                //Set Stock data
                if (columnType == 'ST') {
                  const { quantity, value } = this.getCellValues(
                    stSku.filter(
                      (cell) =>
                        cell.yearMonth == columnIdentifier &&
                        cell.label == 'Total'
                    )
                  );
                  plannerRow.plannerCellDataArray[y].qty = quantity;
                  plannerRow.plannerCellDataArray[y].value = value;
                } else if (columnType == 'LOC') {
                  const monthSKU = stSku.filter((cell) => {
                    return (
                      cell.yearMonth == columnIdentifier &&
                      cell.label == location
                    );
                  });
                  const { quantity, value } = this.getCellValues(monthSKU);
                  plannerRow.plannerCellDataArray[y].qty = quantity;
                  plannerRow.plannerCellDataArray[y].value = value;
                }
                ///////////////////////////////////////////////////
                //set adjustment data
                else if (columnType == 'AGJ') {
                  const monthAgjSku = agjSku.filter(
                    (cell) => cell.yearMonth == columnIdentifier
                  );
                  const { quantity, value } = this.getCellValues(monthAgjSku);
                  plannerRow.plannerCellDataArray[y].qty = quantity;
                  plannerRow.plannerCellDataArray[y].value = value;
                  plannerRow.plannerCellDataArray[y].ids = monthAgjSku
                    .map((cell) => cell.csvIds)
                    .join(',');
                }
                ////////////////////////////////////////////////////////////////////
                //set sales data
                //alocations
                else if (columnType == 'AL') {
                  const monthAlSku = alSku.filter(
                    (cell) => cell.yearMonth == columnIdentifier
                  );
                  const { quantity, value } = this.getCellValues(monthAlSku);
                  plannerRow.plannerCellDataArray[y].qty = quantity;
                  plannerRow.plannerCellDataArray[y].value = value;
                  plannerRow.plannerCellDataArray[y].ids = monthAlSku
                    .map((cell) => cell.csvIds)
                    .join(',');

                  //sales
                } else if (columnType == 'SA') {
                  const monthSaSku = saSku.filter(
                    (cell) => cell.yearMonth == columnIdentifier
                  );
                  const { quantity, value } = this.getCellValues(monthSaSku);
                  plannerRow.plannerCellDataArray[y].qty = quantity;
                  plannerRow.plannerCellDataArray[y].value = value;
                  plannerRow.plannerCellDataArray[y].ids = monthSaSku
                    .map((cell) => cell.csvIds)
                    .join(',');
                }
                //////////////////////////////////////////////////////////////////////////
                //set purchases
                //poms
                else if (columnType == 'POM') {
                  const pom = plannerRow.plannerCellDataArray.filter((cell) => {
                    const [_columnType, _columnIdentifier, _location] =
                      cell.id.split('_');
                    return (
                      (_columnType == 'CompletedPO' ||
                        _columnType == 'ShippedPO' ||
                        _columnType == 'OrderedPO') &&
                      _columnIdentifier.substring(0, 6) == columnIdentifier
                    );
                  });

                  const { quantity, value } = pom.reduce(
                    ({ quantity, value }, currentEntity) => {
                      return {
                        quantity: quantity + (currentEntity.qty ?? 0),
                        value: value + (currentEntity.value ?? 0),
                      };
                    },
                    { quantity: 0, value: 0 }
                  );
                  plannerRow.plannerCellDataArray[y].qty = quantity;
                  plannerRow.plannerCellDataArray[y].value = value;
                } else if (columnType == 'onOrder') {
                  const { quantity, value } = this.getCellValues(onOrderSku);
                  plannerRow.plannerCellDataArray[y].qty = quantity;
                  plannerRow.plannerCellDataArray[y].value = value;
                }
                //po
                else if (
                  columnType == 'CompletedPO' ||
                  columnType == 'ShippedPO' ||
                  columnType == 'OrderedPO'
                ) {
                  let [quantity, value] = [0, 0];

                  const purchaseOrder = purchaseOrders.find(
                    (purchase) =>
                      plannerColumnId.split('_')[2] ==
                      purchase.orderNumber.split('-')[1]
                  );

                  if (purchaseOrder) {
                    const splitorderLines =
                      purchaseOrder.csvProductIdQuantityAmountList.split(',');
                    if (splitorderLines) {
                      const skuLines = splitorderLines.filter(
                        (line) => line.split('_')[0] == product.id
                      );
                      if (skuLines) {
                        quantity = skuLines.reduce(
                          (previousValue, currentValue) => {
                            return (
                              previousValue +
                              parseInt(currentValue.split('_')[1])
                            );
                          },
                          0
                        );
                        value = skuLines.reduce(
                          (previousValue, currentValue) => {
                            return (
                              previousValue +
                              parseInt(currentValue.split('_')[2])
                            );
                          },
                          0
                        );
                      }
                    }
                  }
                  plannerRow.plannerCellDataArray[y].value = value;
                  plannerRow.plannerCellDataArray[y].qty = quantity;
                }
              }
              plannerRows.push(plannerRow);
            }
          }
          this._plannerRows.next(plannerRows);
          console.log(plannerRows);
          this.setColumnsIfHandset();
          return skus;
        })
      );
  }
  setColumnsIfHandset() {
    if (this.breakpoints.isDevice()) {
      let allColumns = this.plannerSettings.getCurrentAllColumns();
      let columns = allColumns.filter(
        (columnId) =>
          columnId.split('_')[1] != 'ProductDetails' ||
          columnId.split('_')[0] == 'SKU'
      );
      this.plannerSettings.setAllColumns(columns);
    }
  }

  getCellValues(arr: PlannerCell[]): { quantity: number; value: number } {
    const { quantity, value } = arr.reduce(
      ({ quantity, value }, currentEntity) => {
        return {
          quantity: quantity + (currentEntity.quantity ?? 0),
          value:
            value + (currentEntity.quantity ?? 0) * (currentEntity.amount ?? 0),
        };
      },
      { quantity: 0, value: 0 }
    );
    return { quantity, value };
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //old

  //sales Lines
  // private _salesLines: BehaviorSubject<PlannerSalesLineTableEntity[]> =
  //   new BehaviorSubject<PlannerSalesLineTableEntity[]>([]);
  // private salesLines$: Observable<PlannerSalesLineTableEntity[]> =
  //   this._salesLines.asObservable();
  // getSalesLines() {
  //   return this.salesLines$;
  // }
  // private setSalesLines(salesLines: PlannerSalesLineTableEntity[]) {
  //   this._salesLines.next(salesLines);
  // }

  // //Adjustment Lines
  // private _adjustmentLines: BehaviorSubject<AdjustmentLineTableEntity[]> =
  //   new BehaviorSubject<AdjustmentLineTableEntity[]>([]);
  // private adjustmentLines$: Observable<AdjustmentLineTableEntity[]> =
  //   this._adjustmentLines.asObservable();
  // getAdjustmentLines() {
  //   return this.adjustmentLines$;
  // }
  // private setAdjustmentLines(adjustmentLines: AdjustmentLineTableEntity[]) {
  //   this._adjustmentLines.next(adjustmentLines);
  // }

  //   private pendingPurchases: string[] = [];
  //   private pendingSales: string[] = [];

  //   private getProductsBySupplier(supplierIds: string, startDate: Date) {
  //     return this.http.get<ProductTableEntity[]>(
  //       this.endpoints.getUrl(`Products?${supplierIds}`)
  //     );
  //   }

  //   private getSalesBySupplier(supplierIds: string, startDate: Date) {
  //     return this.http
  //       .get<PlannerSalesLineTableEntity[]>(
  //         encodeURI(
  //           this.endpoints.getUrl(
  //             `SalesLines?${supplierIds}&fromMonth=${startDate.toUTCString()}`
  //           )
  //         )
  //       )
  //       .pipe(
  //         tap((salesLines) => {
  //           this.setSalesLines(salesLines);
  //         })
  //       );
  //   }
  //   private getAdjustmentsBySupplier(supplierIds: string, startDate: Date) {
  //     return this.http
  //       .get<AdjustmentLineTableEntity[]>(
  //         this.endpoints.getUrl(
  //           `Adjustments?${supplierIds}&fromMonth=${startDate.toUTCString()}`
  //         )
  //       )
  //       .pipe(
  //         tap((adjustmentLines) => {
  //           this.setAdjustmentLines(adjustmentLines);
  //         })
  //       );
  //   }
  //   private getPurchasesBySupplier(supplierIds: string, startDate: Date) {
  //     return this.http.get<PurchaseLineTableEntity[]>(
  //       this.endpoints.getUrl(
  //         `PurchaseLines?${supplierIds}&fromMonth=${startDate.toUTCString()}`
  //       )
  //     );
  //   }
  //   private getPendingPurchases() {
  //     let purchaseIDs = this.generatePendingPurchasesParams();
  //     return this.http.get<PurchaseLineTableEntity[]>(
  //       this.endpoints.getUrl('PendingPurchases') + '?' + purchaseIDs
  //     );
  //   }
  //   private generatePendingPurchasesParams() {
  //     let params = '';
  //     for (let i = 0; i < this.pendingPurchases.length; i++) {
  //       params = params + 'purchaseIDs=' + this.pendingPurchases[i];
  //       if (i < this.pendingPurchases.length - 1) {
  //         params = params + '&';
  //       }
  //     }
  //     return params;
  //   }
  //   private getPendingSales() {
  //     let saleIDs = this.generatePendingSalesParams();
  //     return this.http.get<SalesLineTableEntity[]>(
  //       this.endpoints.getUrl('PendingSales') + '?' + saleIDs
  //     );
  //   }
  //   private generatePendingSalesParams() {
  //     let params = '';
  //     for (let i = 0; i < this.pendingSales.length; i++) {
  //       params = params + 'saleIDs=' + this.pendingSales[i];
  //       if (i < this.pendingSales.length - 1) {
  //         params = params + '&';
  //       }
  //     }
  //     return params;
  //   }

  //   private isValidKey(
  //     key: string,
  //     product: ProductTableEntity
  //   ): key is productKey {
  //     return true;
  //   }
  //   generatePlannerRows(
  //     products: ProductTableEntity[],
  //     sales: PlannerSalesLineTableEntity[],
  //     adjustments: AdjustmentLineTableEntity[],
  //     purchaseLines: PurchaseLineTableEntity[]
  //   ) {
  //     let currentMonthProducts = products.filter((product) => {
  //       return (
  //         product.partitionKey.split('_')[0] ==
  //         this.datePipe.transform(new Date(), 'yyyyMM')
  //       );
  //     });

  //     let locationsWithStock = new Set<string>(
  //       currentMonthProducts.map((product) => product.location)
  //     );
  //     locationsWithStock.delete('');
  //     let plannerColumns = this._plannerColumns.getValue();
  //     for (let location of locationsWithStock) {
  //       plannerColumns.push(
  //         this.generateLOCColumn(
  //           location,
  //           new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  //           this.plannerStaticSettings.locationReverseOrder.indexOf(location) //make location reverse order an editable setting at some point
  //         )
  //       );
  //     }
  //     let purchaseOrderNumbers = [
  //       ...new Set(purchaseLines.map((line) => line.orderNumber)),
  //     ];
  //     const allocations = sales.filter(
  //       (_sale) => _sale.fulfilmentStatus == 'NOT FULFILLED'
  //     );
  //     const fulfilledSales = sales.filter(
  //       (_sale) =>
  //         _sale.fulfilmentStatus == 'FULFILLED' ||
  //         _sale.fulfilmentStatus == 'PARTIALLY FULFILLED'
  //     );
  //     this.pendingSales = [...new Set(allocations.map((line) => line.saleID))];

  //     for (let orderNumber of purchaseOrderNumbers) {
  //       let purchase = purchaseLines.filter(
  //         (line) => line.orderNumber == orderNumber
  //       )[0];
  //       //if not fully recieved add to a list of purchases that we are going to check for updates once the initial data comes back
  //       if (purchase.combinedReceivingStatus != 'FULLY RECEIVED') {
  //         this.pendingPurchases.push(purchase.id);
  //       }
  //       plannerColumns.push({
  //         dateFormat: 'dd/MM/yy',
  //         color:
  //           purchase.combinedReceivingStatus == 'FULLY RECEIVED'
  //             ? 'red'
  //             : purchase.status == 'Shipped'
  //             ? 'BLUE'
  //             : 'GREEN',
  //         id: `${purchase.status}PO_${this.datePipe.transform(
  //           purchase.inStoreDate ?? new Date(),
  //           'yyyyMM'
  //         )}_${purchase.orderNumber.split('-')[1]}`,
  //         name: purchase.orderNumber,
  //         isSticky: false,
  //         date: purchase.inStoreDate ?? new Date(),
  //         bolDate: purchase.bol ?? new Date(),
  //         status: purchase.status,
  //         containerNumber: purchase.containerNumber,
  //         link: 'https://inventory.dearsystems.com/Purchase#' + purchase.id,
  //       });
  //     }

  //     this._plannerColumns.next(plannerColumns);

  //     let productsSeen: Set<string> = new Set<string>();
  //     let plannerRows: PlannerProductData[] = [];
  //     let plannerColumnIds = this._plannerColumns
  //       .getValue()
  //       .map((column) => column.id);
  //     for (let i = 0; i < currentMonthProducts.length; i++) {
  //       const product = currentMonthProducts[i];
  //       // console.log(product.sku, product.billOfMaterial);

  //       if (!productsSeen.has(product.id) && !product.billOfMaterial) {
  //         // console.log(product.sku + ' passed');

  //         productsSeen.add(product.id);
  //         let plannerRow: PlannerProductData = {
  //           id: product.id,
  //           name: product.name,
  //           sku: product.sku,
  //           category: product.category,
  //           onOrder: product.onOrder ?? 0,
  //           plannerCellDataArray: [],
  //         };

  //         const skuProducts: ProductTableEntity[] = products.filter(
  //           (_product) => _product.id == product.id
  //         );
  //         const skuCurrentMonth: ProductTableEntity[] =
  //           currentMonthProducts.filter((_product) => _product.id == product.id);
  //         const skuAdjustments: AdjustmentLineTableEntity[] = adjustments.filter(
  //           (_adjustment) => _adjustment.productID == product.id
  //         );
  //         const skuAllocations: PlannerSalesLineTableEntity[] =
  //           allocations.filter((_sale) => _sale.productID == product.id);

  //         const skuSales: PlannerSalesLineTableEntity[] = fulfilledSales.filter(
  //           (_sale) => _sale.productID == product.id
  //         );
  //         const skuPurchases: PurchaseLineTableEntity[] = purchaseLines.filter(
  //           (_purchase) => _purchase.productID == product.id
  //         );
  //         // const skuProducts: ProductTableEntity[] = products;
  //         // const skuCurrentMonth: ProductTableEntity[] = currentMonthProducts;
  //         // const skuAdjustments: AdjustmentLineTableEntity[] = adjustments;
  //         // const skuAllocations: PlannerSalesLineTableEntity[] = sales;
  //         // const skuSales: PlannerSalesLineTableEntity[] = sales;
  //         // const skuPurchases: PurchaseLineTableEntity[] = purchaseLines;

  //         for (let y = 0; y < plannerColumnIds.length; y++) {
  //           const plannerColumnId = plannerColumnIds[y];
  //           const [columnType, columnIdentifier, location] =
  //             plannerColumnId.split('_');
  //           plannerRow.plannerCellDataArray.push({
  //             id: plannerColumnId,
  //             note: '',
  //             value: 0,
  //             text: '',
  //             qty: 0,
  //           });
  //           //set product details
  //           let t = columnType.toLowerCase();
  //           if (t == 'code') {
  //             t = 'supplierInventoryCode';
  //           } else if (t == 'units per carton') {
  //             t = 'innerCartonQuantity';
  //           } else if (t == 'cartons per pallet') {
  //             t = 'cartonsPerPallet';
  //           }
  //           if (
  //             columnIdentifier == 'ProductDetails' &&
  //             this.isValidKey(t, product)
  //           ) {
  //             plannerRow.plannerCellDataArray[y].text =
  //               product[t]?.toString() ?? '';
  //           }

  //           ////////////////////////////////////////////////////////////
  //           //Set Stock data
  //           if (columnType == 'ST') {
  //             const { quantity, value } = this.getValues(
  //               skuProducts,
  //               columnIdentifier,
  //               product.id,
  //               'averageCost',
  //               'onHand',
  //               'id'
  //             );
  //             plannerRow.plannerCellDataArray[y].value = value;
  //             plannerRow.plannerCellDataArray[y].qty = quantity;
  //           } else if (columnType == 'LOC') {
  //             let locationStock = skuCurrentMonth.filter(
  //               (_product) =>
  //                 _product.location == location && product.id == _product.id
  //             );

  //             const { quantity, value } = locationStock.reduce(
  //               ({ quantity, value }, currentEntity) => {
  //                 return {
  //                   quantity: quantity + (currentEntity.onHand ?? 0),
  //                   value:
  //                     value +
  //                     (currentEntity.onHand ?? 0) *
  //                       (currentEntity.averageCost ?? 0),
  //                 };
  //               },
  //               { quantity: 0, value: 0 }
  //             );
  //             plannerRow.plannerCellDataArray[y].value = value;
  //             plannerRow.plannerCellDataArray[y].qty = quantity;
  //           }
  //           ///////////////////////////////////////////////////
  //           //set adjustment data
  //           else if (columnType == 'AGJ') {
  //             const { quantity, value } = this.getValues(
  //               skuAdjustments,
  //               columnIdentifier,
  //               product.id
  //             );
  //             plannerRow.plannerCellDataArray[y].value = value;
  //             plannerRow.plannerCellDataArray[y].qty = quantity;
  //           }
  //           ////////////////////////////////////////////////////////////////////
  //           //set sales data
  //           //alocations
  //           else if (columnType == 'AL') {
  //             let salesLines = skuAllocations.filter(
  //               (sale) =>
  //                 sale.partitionKey.split('_')[0] == columnIdentifier &&
  //                 sale.productID == product.id &&
  //                 sale.fulfilmentStatus == 'NOT FULFILLED'
  //             );
  //             const { quantity, value } = salesLines.reduce(
  //               ({ quantity, value }, currentEntity) => {
  //                 return {
  //                   quantity: quantity + (currentEntity.quantity ?? 0),
  //                   value: value + (currentEntity.lineCost ?? 0),
  //                 };
  //               },
  //               { quantity: 0, value: 0 }
  //             );
  //             plannerRow.plannerCellDataArray[y].value = value;
  //             plannerRow.plannerCellDataArray[y].qty = quantity;

  //             //sales
  //           } else if (columnType == 'SA') {
  //             let salesLines = skuSales.filter(
  //               (sale) =>
  //                 sale.partitionKey.split('_')[0] == columnIdentifier &&
  //                 sale.productID == product.id &&
  //                 (sale.fulfilmentStatus == 'FULFILLED' ||
  //                   sale.fulfilmentStatus == 'PARTIALLY FULFILLED')
  //             );
  //             const { quantity, value } = salesLines.reduce(
  //               ({ quantity, value }, currentEntity) => {
  //                 return {
  //                   quantity: quantity + (currentEntity.quantity ?? 0),
  //                   value: value + (currentEntity.lineCost ?? 0),
  //                 };
  //               },
  //               { quantity: 0, value: 0 }
  //             );
  //             plannerRow.plannerCellDataArray[y].value = value;
  //             plannerRow.plannerCellDataArray[y].qty = quantity;

  //             ///////////////////////////////////////////////////////////////////
  //             //purchases
  //           } else if (
  //             columnType == 'CompletedPO' ||
  //             columnType == 'ShippedPO' ||
  //             columnType == 'OrderedPO'
  //           ) {
  //             let orderLines = skuPurchases.filter(
  //               (purchase) =>
  //                 plannerColumnId.split('_')[2] ==
  //                   purchase.orderNumber.split('-')[1] &&
  //                 purchase.productID == product.id
  //             );
  //             const { quantity, value } = orderLines.reduce(
  //               ({ quantity, value }, currentEntity) => {
  //                 return {
  //                   quantity: quantity + (currentEntity.quantity ?? 0),
  //                   value:
  //                     value +
  //                     (currentEntity.quantity ?? 0) * (currentEntity.cost ?? 0),
  //                 };
  //               },
  //               { quantity: 0, value: 0 }
  //             );
  //             plannerRow.plannerCellDataArray[y].value = value;
  //             plannerRow.plannerCellDataArray[y].qty = quantity;

  //             //purchase order accumulator
  //           } else if (columnType == 'POM') {
  //             let orderLines = skuPurchases.filter(
  //               (purchase) =>
  //                 this.datePipe.transform(purchase.inStoreDate, 'yyyyMM') ==
  //                   columnIdentifier && purchase.productID == product.id
  //             );
  //             const { quantity, value } = orderLines.reduce(
  //               ({ quantity, value }, currentEntity) => {
  //                 return {
  //                   quantity: quantity + (currentEntity.quantity ?? 0),
  //                   value:
  //                     value +
  //                     (currentEntity.quantity ?? 0) * (currentEntity.cost ?? 0),
  //                 };
  //               },
  //               { quantity: 0, value: 0 }
  //             );
  //             plannerRow.plannerCellDataArray[y].value = value;
  //             plannerRow.plannerCellDataArray[y].qty = quantity;
  //           } else if (columnType == 'onOrder') {
  //             let orderLines = skuPurchases.filter(
  //               (purchase) =>
  //                 purchase.status !== 'Completed' &&
  //                 purchase.productID == product.id
  //             );
  //             const { quantity, value } = orderLines.reduce(
  //               ({ quantity, value }, currentEntity) => {
  //                 return {
  //                   quantity: quantity + (currentEntity.quantity ?? 0),
  //                   value:
  //                     value +
  //                     (currentEntity.quantity ?? 0) * (currentEntity.cost ?? 0),
  //                 };
  //               },
  //               { quantity: 0, value: 0 }
  //             );
  //             plannerRow.plannerCellDataArray[y].value = value;
  //             plannerRow.plannerCellDataArray[y].qty = quantity;
  //           }
  //         }

  //         plannerRows.push(plannerRow);
  //       }
  //     }
  //     this._plannerRows.next(plannerRows);
  //     console.log('finished generating planner rows');
  //   }
  // getPlanner(supplierIds: string) {
  //   this.http
  //     .get(this.endpoints.getUrl('planners') + '?' + supplierIds)
  //     .subscribe((_) => console.log(_));
  //   this._dataFetchInProgress.next(true);
  //   this.pendingPurchases = [];
  //   this._plannerRows.next([]);
  //   let startDate = new Date();
  //   startDate.setMonth(
  //     startDate.getMonth() - this.plannerStaticSettings.pastMonthsToShow
  //   ); //make past months to show an editable option at some point

  //   this._plannerColumns.next([]);

  //   this.plannerSettings
  //     .getSettings(this.userName, supplierIds.split('=')[1])
  //     .pipe(
  //       switchMap((settings) => {
  //         this._plannerColumns.next(
  //           this.generateColumns(startDate, settings.productDetailsOrder)
  //         );
  //         return forkJoin([
  //           this.getProductsBySupplier(supplierIds, startDate),
  //           this.getSalesBySupplier(supplierIds, startDate),
  //           this.getAdjustmentsBySupplier(supplierIds, startDate),
  //           this.getPurchasesBySupplier(supplierIds, startDate),
  //         ]).pipe(
  //           share(),
  //           map(([products, sales, adjustments, purchases]) => {
  //             return this.generatePlannerRows(
  //               products,
  //               sales,
  //               adjustments,
  //               purchases
  //             );
  //           }),
  //           switchMap((_) => {
  //             this.setColumnsIfHandset();
  //             this._dataFetchInProgress.next(false);
  //             return forkJoin([
  //               this.getPendingPurchases(),
  //               this.getPendingSales(),
  //             ]);
  //           }),
  //           tap(([pendingPurchases, pendingSales]) => {
  //             let plannerColumnData = this._plannerColumns.getValue();

  //             for (const purchase of pendingPurchases) {
  //               const colIndex = plannerColumnData.findIndex(
  //                 (col) => col.name == purchase.orderNumber
  //               );
  //               if (colIndex != -1) {
  //                 plannerColumnData[colIndex].status = purchase.status;
  //                 plannerColumnData[colIndex].date =
  //                   purchase.inStoreDate ?? new Date();
  //                 plannerColumnData[colIndex].id = `${
  //                   purchase.status
  //                 }PO_${this.datePipe.transform(
  //                   purchase.inStoreDate ?? new Date(),
  //                   'yyyyMM'
  //                 )}_${purchase.orderNumber.split('-')[1]}`;
  //                 plannerColumnData[colIndex].containerNumber =
  //                   purchase.containerNumber;
  //                 plannerColumnData[colIndex].bolDate =
  //                   purchase.bol ?? new Date();
  //                 plannerColumnData[colIndex].color =
  //                   purchase.combinedReceivingStatus == 'FULLY RECEIVED'
  //                     ? 'red'
  //                     : purchase.status == 'Shipped'
  //                     ? 'BLUE'
  //                     : 'GREEN';
  //               }
  //             }

  //             this._plannerColumns.next(plannerColumnData);
  //             this.setColumnsIfHandset();
  //           })
  //         );
  //       })
  //     )
  //     .subscribe();
  // }

  //   getValues(
  //     arr: any[],
  //     columnIdentifier: string,
  //     productId: string,

  //     costParam: string = 'cost',
  //     quantityParam: string = 'quantity',
  //     productIdParam: string = 'productID'
  //   ): { quantity: number; value: number } {
  //     // console.log(arr);
  //     // console.log(columnIdentifier);
  //     // console.log(productId);

  //     // console.log(costParam);
  //     // console.log(quantityParam);

  //     let lines = arr.filter(
  //       (line) =>
  //         line.partitionKey.split('_')[0] == columnIdentifier &&
  //         line[productIdParam] == productId
  //     );
  //     // console.log(lines);

  //     const { quantity, value } = lines.reduce(
  //       ({ quantity, value }, currentEntity) => {
  //         return {
  //           quantity: quantity + (currentEntity[quantityParam] ?? 0),
  //           value:
  //             value +
  //             (currentEntity[quantityParam] ?? 0) *
  //               (currentEntity[costParam] ?? 0),
  //         };
  //       },
  //       { quantity: 0, value: 0 }
  //     );
  //     return { quantity, value };
  //   }
}
