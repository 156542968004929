<ng-container *ngIf="columnId && productData.id">
  <!-- sku - links to product page in dear -->
  <span *ngIf="isSKUColumn()">
    <!-- <p *ngIf="handset$ | async" class="mat-18" [matTooltip]="productData.name">
      ?
    </p> -->
    <a
      [href]="'https://inventory.dearsystems.com/Product#' + productData.id"
      target="_blank"
      >{{ text }}</a
    >
    <p class="handset-name" *ngIf="isDevice" [matTooltip]="productData.name">
      {{ productData.name }}
    </p>
  </span>
  <!-- other details columns - non clickable -->
  <ng-container *ngIf="isDetailsColumn() && !isSKUColumn()">
    <p style="padding: 0px 10px 0px 10px">{{ text }}</p>
  </ng-container>
  <!-- PO, ST, LOC - all cant show details - format as number -->
  <ng-container *ngIf="!isDetailsColumn() && !canShowDetails()">
    <p *ngIf="qty != 0 && (showQty$ | async)">
      {{ qty | number: "1.0-0" }}
    </p>
    <mat-divider *ngIf="(showQty$ | async) && (showVal$ | async)">
    </mat-divider>
    <p *ngIf="value != 0 && (showVal$ | async)">
      ${{ value | number: "1.0-0" }}
    </p>
  </ng-container>
  <!-- SA, AGJ, AL - can show details -->
  <ng-container *ngIf="!isDetailsColumn() && canShowDetails()">
    <button mat-button (click)="toggleShowDetails()">
      <p *ngIf="qty != 0 && (showQty$ | async)">
        {{ qty | number: "1.0-0" }}
      </p>
      <mat-divider *ngIf="(showQty$ | async) && (showVal$ | async)">
      </mat-divider>
      <p *ngIf="value != 0 && (showVal$ | async)">
        ${{ value | number: "1.0-0" }}
      </p>
    </button>
  </ng-container>
</ng-container>
