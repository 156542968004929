import { AdminService } from './../../services/admin.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { UserTableEntity } from '../../models/UserTableEntity';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private adminService: AdminService
  ) {}
  users: UserTableEntity[] = [];

  ngOnInit(): void {
    this.authService
      .getAccessTokenAndCallGraphAPI()
      .pipe(
        switchMap((users) => {
          return this.adminService.getUsers().pipe(
            tap((userTableEnitities) => {
              let returnEntities: UserTableEntity[] = [];
              for (let i = 0; i < users.value.length; i++) {
                const user = users.value[i];
                const entityIndex = userTableEnitities.findIndex(
                  (userz) => userz.displayName == user.displayName
                );
                if (entityIndex != -1) {
                  returnEntities.push(userTableEnitities[entityIndex]);
                } else {
                  returnEntities.push({
                    displayName: user.displayName,
                    planners: false,
                    salesReports: false,
                    admin: false,
                  });
                }
              }
              this.users = returnEntities;
            })
          );
        })
      )
      .subscribe();
  }
  onChange() {
    console.log(this.users);

    this.adminService.setUsers(this.users);
  }
}
