<div style="width: 10px"></div>
<!-- expand -->
<button mat-icon-button class="settings" (click)="expanded = !expanded">
  <mat-icon *ngIf="expanded">expand_less</mat-icon>
  <mat-icon *ngIf="!expanded">expand_more</mat-icon
  ><mat-icon *ngIf="!expanded">settings</mat-icon>
</button>
<!-- print -->

<div>
  <mat-label>Display From</mat-label>
  <button mat-icon-button [matMenuTriggerFor]="displayFromMenuRef">
    <mat-icon>date_range</mat-icon>
  </button>
  <mat-menu #displayFromMenuRef="matMenu">
    <button
      mat-menu-item
      *ngFor="let yearMonth of allYearMonths$ | async"
      (click)="onDisplayFromChange(yearMonth)"
      value="yearMonth"
    >
      {{ yearMonth }}
    </button>
  </mat-menu>
</div>
<!-- product detail rearrangement -->
<div>
  <mat-label>Rearrange Columns</mat-label>
  <button
    mat-icon-button
    [matMenuTriggerFor]="rearrangeColumnSelectorMenu"
    class="settings"
    (click)="onOpenColumnSelectorClick(filterInput.value, exclusionInput.value)"
  >
    <mat-icon>swap_horiz</mat-icon>
  </button>
  <mat-menu
    #rearrangeColumnSelectorMenu="matMenu"
    yPosition="below"
    xPosition="after"
    (closed)="onColumnSelectorClosed()"
  >
    <div
      cdkDropList
      (cdkDropListDropped)="onOpenRearrangeColumnSelectorDrop($event)"
    >
      <ng-container *ngFor="let column of plannerColumns$ | async">
        <ng-container *ngIf="column.includes('ProductDetails')">
          <div
            mat-menu-item
            [style.color]="
              (displayedColumns$ | async)?.includes(column) ? '' : 'LightGrey'
            "
            cdkDrag
          >
            {{ column.split("_")[0] }}
          </div>
        </ng-container>
      </ng-container>
    </div>
  </mat-menu>
</div>
<!-- column selector -->
<div>
  <mat-label>Hide Columns</mat-label>
  <button
    mat-icon-button
    [matMenuTriggerFor]="columnSelectorMenu"
    class="settings"
    (click)="onOpenColumnSelectorClick(filterInput.value, exclusionInput.value)"
  >
    <mat-icon>settings</mat-icon>
  </button>
  <mat-menu
    #columnSelectorMenu="matMenu"
    yPosition="below"
    xPosition="after"
    (closed)="onColumnSelectorClosed()"
  >
    <div mat-menu-item *ngFor="let column of plannerColumns$ | async">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="onColumnSelectionChange($event, column)"
        [checked]="columnSelection.isSelected(column)"
        >{{ column }}
      </mat-checkbox>
    </div>
  </mat-menu>
</div>
<!-- columnType selector -->
<div>
  <mat-label>Hide Column Types</mat-label>
  <button
    mat-icon-button
    [matMenuTriggerFor]="columnTypeSelectorMenu"
    class="settings"
    (click)="onOpenColumnSelectorClick(filterInput.value, exclusionInput.value)"
  >
    <mat-icon>settings</mat-icon>
  </button>
  <mat-menu
    #columnTypeSelectorMenu="matMenu"
    yPosition="below"
    xPosition="after"
    (closed)="onColumnSelectorClosed()"
  >
    <div mat-menu-item *ngFor="let columnType of plannerColumnTypes$ | async">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="onColumnTypeSelectionChange($event, columnType)"
        [checked]="columnTypeSelection.isSelected(columnType)"
        >Hide All {{ columnType }} Columns
      </mat-checkbox>
    </div>
  </mat-menu>
</div>
<!-- filters -->
<div>
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Filter"
      #filterInput
      [value]="currentFilter$ | async"
    />
  </mat-form-field>
  <button
    mat-icon-button
    *ngIf="
      filterInput.value.length &&
      !(savedFilters$ | async)?.includes(filterInput.value)
    "
    (click)="onSaveFilterClick(filterInput.value)"
  >
    <mat-icon>save</mat-icon>
  </button>
  <button
    mat-icon-button
    [matMenuTriggerFor]="savedFiltersMenu"
    class="settings"
    *ngIf="(savedFilters$ | async)?.length"
  >
    <mat-icon>filter_list</mat-icon>
  </button>
  <mat-menu #savedFiltersMenu="matMenu" yPosition="below" xPosition="after">
    <mat-list-item *ngFor="let savedFilter of savedFilters$ | async">
      <button mat-button (click)="onSelectSavedFilterClick(savedFilter)">
        {{ savedFilter }}
      </button>
    </mat-list-item>
  </mat-menu>
</div>

<!-- Exclude -->
<div>
  <mat-form-field>
    <mat-label>Exclude</mat-label>
    <input
      matInput
      (keyup)="applyExclusion($event)"
      placeholder="Exclude"
      #exclusionInput
      [value]="currentExclusion$ | async"
    />
  </mat-form-field>
  <button
    mat-icon-button
    *ngIf="
      exclusionInput.value.length &&
      !(savedExclusions$ | async)?.includes(exclusionInput.value)
    "
    (click)="onSaveExclusionClick(exclusionInput.value)"
  >
    <mat-icon>save</mat-icon>
  </button>
  <button
    mat-icon-button
    [matMenuTriggerFor]="savedExclusionsMenu"
    class="settings"
    *ngIf="(savedExclusions$ | async)?.length"
  >
    <mat-icon>filter_list</mat-icon>
  </button>

  <mat-menu #savedExclusionsMenu="matMenu" yPosition="below" xPosition="after">
    <mat-list-item *ngFor="let savedExclusion of savedExclusions$ | async">
      <button mat-button (click)="onSelectSavedExclusionClick(savedExclusion)">
        {{ savedExclusion }}
      </button>
    </mat-list-item>
  </mat-menu>
</div>

<!-- cell display type -->

<mat-button-toggle-group
  [multiple]="true"
  (change)="onCellDisplayTypeChange($event)"
>
  <mat-button-toggle value="val" [checked]="showVal$ | async">
    val
  </mat-button-toggle>
  <mat-button-toggle
    value="qty"
    [checked]="(showQty$ | async) || !(showVal$ | async)"
  >
    qty
  </mat-button-toggle>
</mat-button-toggle-group>
<!-- export to csv -->
<!-- <app-export
    class="download"
    [data]="exportData"
    [options]="csvExportOptions"
  ></app-export> -->
<!-- groupby -->
<mat-form-field>
  <mat-label>Group By</mat-label>
  <mat-select
    placeholder="Group By"
    (selectionChange)="onGroupByChange($event)"
    [value]="groupById$ | async"
  >
    <mat-option value=""></mat-option>
    <mat-option value="CATEGORY_ProductDetails"> Category </mat-option>
    <mat-option value="TAGS_ProductDetails"> Tags </mat-option>
  </mat-select>
</mat-form-field>

<!-- show totals -->
<mat-slide-toggle
  [checked]="showTotals$ | async"
  class="show-totals"
  (change)="onShowTotalsChange($event)"
  ><mat-icon class="show-totals">functions</mat-icon></mat-slide-toggle
>

<div style="width: 10px"></div>
