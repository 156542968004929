import { Injectable } from '@angular/core';
import { Options } from 'angular2-csv';
import { BehaviorSubject, Observable } from 'rxjs';
import { PlannerProductData } from 'src/app/models/PlannerProductData';

@Injectable({
  providedIn: 'root',
})
export class PlannerExportService {
  constructor() {}
  //csv export options

  private _csvExportOptions: BehaviorSubject<Options> =
    new BehaviorSubject<Options>({
      filename: 'plannerexport.csv',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      headers: [],
      showTitle: true,
      title: '',
      useBom: false,
      removeNewLines: true,
      keys: [],
    });
  getCsvExportOptions(fileName: string, headers: string[], keys: string[]) {
    let options = this._csvExportOptions.getValue();
    options.filename = fileName;
    options.headers = headers;
    options.keys = keys;
    return options;
  }
  setCsvExportOptions(csvExportOptions: Options) {
    this._csvExportOptions.next(csvExportOptions);
  }

  private _renderedData: BehaviorSubject<PlannerProductData[]> =
    new BehaviorSubject<PlannerProductData[]>([]);
  private renderedData$: Observable<PlannerProductData[]> =
    this._renderedData.asObservable();

  setRenderedData(data: PlannerProductData[]) {
    this._renderedData.next(data);
  }
  getRenderedData() {
    return this.renderedData$;
  }
}
