<button
  mat-icon-button
  [routerLink]="['/']"
  routerLinkActive="router-link-active"
>
  <mat-icon>keyboard_arrow_left</mat-icon>
</button>
<div class="table" *ngIf="users.length">
  <div class="table-row">
    <h3>Name</h3>
    <h3>Planners</h3>
    <h3>Sales Reports</h3>
    <h3>Admin</h3>
  </div>

  <div class="table-row" *ngFor="let item of users">
    <p>{{ item.displayName }}</p>
    <div>
      <mat-checkbox
        (change)="onChange()"
        [(ngModel)]="item.planners"
      ></mat-checkbox>
    </div>
    <div>
      <mat-checkbox
        (change)="onChange()"
        [(ngModel)]="item.salesReports"
      ></mat-checkbox>
    </div>
    <div>
      <mat-checkbox
        (change)="onChange()"
        [(ngModel)]="item.admin"
      ></mat-checkbox>
    </div>
  </div>
</div>
