import { DataSource } from '@angular/cdk/table';
import { AdjustmentLineTableEntity } from './../../models/AdjustmentLineSaleEnity';
import { BreakpointsService } from 'src/app/shared/services/breakpoints.service';
import { PlannerExportService } from './../../shared/services/planner-export.service';
import { DatePipe } from '@angular/common';
import { PlannerSettingsService } from './../planner-settings.service';
import { PlannerService } from './../planner.service';
import { PlannerSalesLineTableEntity } from './../../models/PlannerSalesLineTableEntity';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { Options } from 'angular2-csv';
import { SalesDetailArgs } from 'src/app/models/SalesDetailArgs';
import { MatPaginator } from '@angular/material/paginator';
import { Details, Order } from 'src/app/models/Planner';

@Component({
  selector: 'app-sales-detail',
  templateUrl: './sales-detail.component.html',
  styleUrls: ['./sales-detail.component.scss'],
})
export class SalesDetailComponent implements OnInit {
  // @Input() salesDetailArgs: SalesDetailArgs | null = null;
  constructor(
    private route: ActivatedRoute,
    private plannerService: PlannerService,
    private plannerSettings: PlannerSettingsService,
    private csvExportService: PlannerExportService,
    private datePipe: DatePipe,
    private breakpoints: BreakpointsService
  ) {
    // this.route.url.subscribe((url) => {
    //   var type = url[0].path;
    //   if (type == 'SA') {
    //     this.type = 'Sales';
    //     this.fulFilmentStatus = 'FULFILLED';
    //   } else if (type == 'AL') {
    //     this.type = 'Allocations';
    //     this.fulFilmentStatus = 'NOT FULFILLED';
    //   }
    //   this.columnIdentifier = url[1].path;
    //   this.month = new Date(
    //     new Number(this.columnIdentifier.substring(0, 4)).valueOf(),
    //     new Number(this.columnIdentifier.substring(4, 7)).valueOf() - 1,
    //     1
    //   );
    //   this.productId = url[2].path;
    // });
  }
  adjustmentsColumns: string[] = [
    'effectiveDate',
    'stocktakeNumber',
    'quantity',
    'reference',
    'status',
  ];
  salesColumns: string[] = [
    'shipmentDate',
    'orderNumber',
    'customer',
    'sku',
    'quantity',
    'price',
    'discount',
    'lineTotal',
    'fulfilmentStatus',
  ];
  columns: string[] = ['orderNumber', 'customer', 'quantity', 'amount'];
  csvExportOptions: Options = this.csvExportService.getCsvExportOptions(
    'salesdetailexport.csv',
    this.columns,
    this.columns
  );
  _heading: BehaviorSubject<string> = new BehaviorSubject<string>('');
  heading$: Observable<string> = this._heading.asObservable();

  sku: string = '';
  name: string = '';
  type: string = '';
  month: Date = new Date();
  isHandset: boolean = this.breakpoints.isDevice();
  fulFilmentStatus: string = '';
  orders: Order[] = [];
  dataSource = new MatTableDataSource<Details>();
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  ngOnInit(): void {
    this.plannerSettings
      .getSalesDetailArgs()
      .pipe(
        map((salesDetailArgs) => {
          this.orders = [];
          let dets: Details[] = [];
          if (salesDetailArgs) {
            this.name = salesDetailArgs.name;
            this.sku = salesDetailArgs.sku;
            this.month = new Date(
              new Number(salesDetailArgs.yearMonth.substring(0, 4)).valueOf(),
              new Number(salesDetailArgs.yearMonth.substring(4, 7)).valueOf() -
                1,
              1
            );
            if (salesDetailArgs.type == 'SA') {
              this.type = 'Sales';
              this.fulFilmentStatus = 'FULFILLED';
            } else if (salesDetailArgs.type == 'AL') {
              this.type = 'Allocations';
              this.fulFilmentStatus = 'NOT FULFILLED';
            } else if (salesDetailArgs.type == 'AGJ') {
              this.type = 'Adjustments';
            }
            let lines = salesDetailArgs.ids.split(',');
            for (const line of lines) {
              const [id, orderNumber, quantity, amount] = line.split('_');
              dets.push({
                id: id,
                orderNumber: orderNumber,
                quantity: parseInt(quantity),
                amount: parseInt(amount),
              });
            }
            return dets;
          } else {
            return [];
          }
        }),
        switchMap((lines) => {
          this.dataSource.data = lines;
          this._heading.next(
            `${this.type} ${
              this.type == 'AL'
                ? ''
                : this.datePipe.transform(this.month, 'MMMM yyyy')
            } - ${this.sku} ${this.name}`
          );
          this.csvExportOptions = this.csvExportService.getCsvExportOptions(
            'salesdetailexport.csv',
            this.columns,
            this.columns
          );
          this.csvExportOptions.title = this._heading.getValue();
          return this.plannerService.getOrderCustomers(
            lines.map((line) => line.id)
          );
        })
      )
      .subscribe((orders) => {
        console.log(orders);

        this.orders = orders;
      });
  }
  getCustomerId(saleId: string) {
    return (
      this.orders.find((order) => order?.saleId == saleId)?.customerId ?? ''
    );
  }
  getCustomerName(saleId: string) {
    return (
      this.orders.find((order) => order?.saleId == saleId)?.customerName ?? ''
    );
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  onBackToPlannerClick() {
    this.plannerSettings.setShowDetailsSetting(false);
  }

  getTotal(column: string) {
    if (column === 'quantity') {
      return this.dataSource.data.reduce((acc, cur) => {
        if (cur.quantity) {
          return (acc + cur?.quantity) * -1;
        }
        return acc * -1;
      }, 0);
    } else if (column == 'customer') {
      return 'Total Quantity: ';
    } else if (column == 'discount') {
      return 'Total Sales: ';
    } else if (column == 'amount') {
      return this.dataSource.data.reduce((acc, cur) => {
        if (cur.amount) {
          return (acc + cur?.amount) * -1;
        }
        return acc * -1;
      }, 0);
    }
    return '';
  }
}
