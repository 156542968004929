import { BreakpointsService } from 'src/app/shared/services/breakpoints.service';
import { Component, Input, OnInit } from '@angular/core';
import { PlannerColumnData } from 'src/app/models/PlannerColumnData';
import { Observable } from 'rxjs';
import { PlannerService } from '../planner.service';

@Component({
  selector: 'app-planner-header-cell',
  templateUrl: './planner-header-cell.component.html',
  styleUrls: ['./planner-header-cell.component.scss'],
})
export class PlannerHeaderCellComponent implements OnInit {
  @Input() column: PlannerColumnData = {
    id: '',
    name: '',
    color: '',
    isSticky: false,
    date: new Date(),
    containerNumber: '',
    bolDate: undefined,
    status: '',
    link: '',
    dateFormat: '',
  };
  constructor(
    private breakpoints: BreakpointsService,
    private plannerService: PlannerService
  ) {}
  dataFetchInProgress$: Observable<boolean> =
    this.plannerService.getDataFetchStatus();
  show: boolean = !this.breakpoints.isHandset();
  ngOnInit(): void {}
}
