<div class="details-comp">
  <div class="heading-row">
    <button
      *ngIf="!isHandset"
      mat-raised-button
      (click)="onBackToPlannerClick()"
    >
      Back To Planner
    </button>
    <button
      mat-icon-button
      class="settings"
      *ngIf="isHandset"
      (click)="onBackToPlannerClick()"
    >
      <mat-icon>expand_less</mat-icon>
    </button>
    <span *ngIf="!isHandset" style="flex-grow: 1"></span>
    <h2>
      <ng-container>{{ heading$ | async }}</ng-container>
    </h2>
    <span *ngIf="!isHandset" style="flex-grow: 1"></span>
    <span *ngIf="!isHandset" style="width: 133px">
      <app-export
        class="download"
        [data]="dataSource.data"
        [options]="csvExportOptions"
      ></app-export
    ></span>
    <app-export
      *ngIf="isHandset"
      class="download"
      [data]="dataSource.data"
      [options]="csvExportOptions"
    ></app-export>
  </div>
  <div class="hidden-heading-row">
    <h2>
      <ng-container>{{ heading$ | async }}</ng-container>
    </h2>
  </div>
  <table mat-table #table [dataSource]="dataSource" matSort>
    <ng-container *ngFor="let column of columns" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef class="header" mat-sort-header>
        {{ column.toUpperCase() }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span [ngSwitch]="true">
          <ng-container *ngSwitchCase="column == 'quantity'">
            {{ row[column] * -1 }}
          </ng-container>
          <ng-container *ngSwitchCase="column == 'amount'">
            {{ row[column] * -1 | currency }}
          </ng-container>

          <ng-container *ngSwitchCase="column == 'orderNumber'">
            <a
              [href]="'https://inventory.dearsystems.com/Sale#' + row.id"
              target="_blank"
              >{{ row[column] }}</a
            >
          </ng-container>
          <ng-container *ngSwitchCase="column == 'customer'">
            <a
              [href]="
                'https://inventory.dearsystems.com/Customer#' +
                getCustomerId(row.id)
              "
              target="_blank"
              >{{ getCustomerName(row.id) }}</a
            >
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ row[column] }}
          </ng-container>
        </span>
      </td>
      <td
        mat-footer-cell
        *matFooterCellDef
        [ngStyle]="{
          'text-align':
            column != 'amount' && column != 'quantity' ? 'right' : 'left'
        }"
      >
        <ng-container *ngIf="column == 'amount'">{{
          getTotal(column) | currency
        }}</ng-container>
        <ng-container *ngIf="column == 'quantity'">{{
          getTotal(column)
        }}</ng-container>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
    <tr mat-footer-row *matFooterRowDef="columns"></tr>
  </table>
  <!-- <table mat-table #table [dataSource]="dataSource" matSort>
    <ng-container *ngFor="let column of columns" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef class="header" mat-sort-header>
        {{ column.toUpperCase() }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span [ngSwitch]="true">
          <ng-container *ngSwitchCase="column == 'shipmentDate'">
            {{ row[column] | date: "dd/MM/yy" }}
          </ng-container>
          <ng-container
            *ngSwitchCase="column == 'price' || column == 'lineTotal'"
          >
            {{ row[column] | currency }}
          </ng-container>
          <ng-container *ngSwitchCase="column == 'discount'">
            {{ row[column] / 100 | percent: "1.2-2" }}
          </ng-container>
          <ng-container *ngSwitchCase="column == 'orderNumber'">
            <a
              [href]="'https://inventory.dearsystems.com/Sale#' + row.saleID"
              target="_blank"
              >{{ row[column] }}</a
            >
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ row[column] }}
          </ng-container>
        </span>
      </td>
      <td
        mat-footer-cell
        *matFooterCellDef
        [ngStyle]="{
          'text-align':
            column != 'lineTotal' && column != 'quantity' ? 'right' : 'left'
        }"
      >
        <ng-container *ngIf="column == 'lineTotal'">{{
          getTotal(column) | currency
        }}</ng-container>
        <ng-container *ngIf="column != 'lineTotal'">{{
          getTotal(column)
        }}</ng-container>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
    <tr mat-footer-row *matFooterRowDef="columns"></tr>
  </table> -->
</div>
<div class="fill"></div>

<!-- <span class="back-paginator">
  <button
    mat-icon-button
    class="settings"
    *ngIf="isHandset"
    (click)="onBackToPlannerClick()"
  >
    <mat-icon>expand_less</mat-icon>
  </button>

  <app-export
    class="download"
    [data]="dataSource.data"
    [options]="csvExportOptions"
  ></app-export>
  <mat-paginator
    class="paginator"
    [pageSize]="50"
    [pageSizeOptions]="[5, 10, 20, 50, 100]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</span> -->
