import { PlannerService } from './../planner.service';
import { PlannerSettingsService } from './../planner-settings.service';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-planner-no-data-row',
  templateUrl: './planner-no-data-row.component.html',
  styleUrls: ['./planner-no-data-row.component.scss'],
})
export class PlannerNoDataRowComponent implements OnInit {
  dataFetchInProgress$: Observable<boolean> =
    this.plannerService.getDataFetchStatus();
  currentFilter$: Observable<string> = this.plannerSettings.getCurrentFilter();
  constructor(
    private plannerSettings: PlannerSettingsService,
    private plannerService: PlannerService
  ) {}

  ngOnInit(): void {}
}
