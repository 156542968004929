import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { HttpHeaders, HttpClient } from '@angular/common/http';
interface User {
  displayName: string;
}
interface Users {
  value: User[];
}

interface ActiveAccountInfo extends AccountInfo {
  idTokenClaims?: {
    tfp?: string;
    emails?: string[];
    given_name: string;
    family_name: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _destroying$ = new Subject<void>();
  private _loginDisplay: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private loginDisplay$: Observable<boolean> =
    this._loginDisplay.asObservable();
  private _redirecting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private redirecting$: Observable<boolean> = this._redirecting.asObservable();
  private _name: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private name$: Observable<string> = this._name.asObservable();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient
  ) {}

  getRedirectingStatus() {
    return this.redirecting$;
  }
  setRedirectingStatus(status: boolean) {
    return this._redirecting.next(status);
  }
  getLoggedInStatus(): Observable<boolean> {
    return this.loginDisplay$;
  }
  getLoggedInUserName() {
    return this.name$.pipe(shareReplay(1));
  }
  configure(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        tap((msg: EventMessage) => {
          if (msg.eventType == EventType.HANDLE_REDIRECT_START) {
            this._redirecting.next(true);
          } else if (msg.eventType === EventType.HANDLE_REDIRECT_END) {
            this._redirecting.next(false);
          }
        }),
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        this.checkAndSetActiveAccount();
      });
  }

  checkAndSetActiveAccount(): void {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount) {
      const account = <ActiveAccountInfo>activeAccount;

      if (this._name.getValue() == '') {
        console.log('setting user name');
        this._name.next(account.username);
      }
      this._loginDisplay.next(true);
    }
    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.msalService.instance.getAllAccounts();
      for (let i = 0; i < accounts.length; i++) {
        const account = <ActiveAccountInfo>accounts[i];
        this.msalService.instance.setActiveAccount(account);
        console.log('setting user name');

        this._name.next(account.username);
        this._loginDisplay.next(true);
      }
    }
  }
  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
            console.log(response);
            console.log('setting user name');
            this._name.next(response?.account?.username ?? '');
            this._loginDisplay.next(true);
          });
      } else {
        this.msalService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
            console.log('setting user name');
            this._name.next(response?.account?.username ?? '');
            this._loginDisplay.next(true);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    }
  }
  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService
        .loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest)
        .subscribe();
    } else {
      this.msalService.loginRedirect().subscribe();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
        });
    } else {
      this.msalService
        .loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
        });
    }
  }
  getAccessTokenAndCallGraphAPI() {
    return this.msalService
      .acquireTokenSilent({
        scopes: ['user.read.all'],
      })
      .pipe(
        switchMap((result) => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + result.accessToken,
            }),
          };

          return this.http.get<Users>(
            'https://graph.microsoft.com/v1.0/users',
            httpOptions
          );
        })
      );
  }
  logout(popup?: boolean) {
    if (popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.msalService.logoutRedirect();
    }
  }
  destroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
