import { PlannerStaticSettingsService } from './../planner/planner-static-settings.service';
import { PlannerService } from './../planner/planner.service';
import { PlannerStylesService } from './../planner/planner-styles.service';
import { Observable, of, BehaviorSubject, fromEvent } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Location } from '@angular/common';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-styles',
  templateUrl: './styles.component.html',
  styleUrls: ['./styles.component.scss'],
})
export class StylesComponent implements OnInit {
  horizontalGridLines$: Observable<boolean> =
    this.plannerStyles.getHorizontalGridLinesSetting();
  verticalGridLines$: Observable<boolean> =
    this.plannerStyles.getVerticalGridLinesSetting();
  plannerColumnTypes$: Observable<string[]> =
    this.plannerStaticSettings.getAllColumnTypes();
  constructor(
    private plannerStyles: PlannerStylesService,
    private plannerStaticSettings: PlannerStaticSettingsService,
    private location: Location
  ) {}

  ngOnInit(): void {}
  onVerticalGridLineChanged(e: MatSlideToggleChange) {
    this.plannerStyles.setVerticalGridLinesSetting(e.checked);
  }
  onHorizontalGridLineChanged(e: MatSlideToggleChange) {
    this.plannerStyles.setHorizontalGridLinesSetting(e.checked);
  }

  onBackClick() {
    this.location.back();
  }

  // onColorChange(e: NgxMatColorPickerInputEvent, columnType: string) {

  // }
}
