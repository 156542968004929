import { SignalRService } from './signal-r.service';
import { PlannerStylesService } from './planner/planner-styles.service';
import { PlannerSettingsService } from './planner/planner-settings.service';
import { Component, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private authService: AuthService
  ) // private messageService: SignalRService
  {}
  plannersDown = !true;
  title = 'Planner';
  isIframe = false;
  loginDisplay$: Observable<boolean> = this.authService.getLoggedInStatus();
  name$: Observable<string> = this.authService.getLoggedInUserName();
  ngOnInit(): void {
    if (!this.plannersDown) {
      this.isIframe = window !== window.parent && !window.opener;
    }
  }
  ngAfterViewInit(): void {
    if (!this.plannersDown) {
      this.authService.configure();
      // this.messageService.register();
      this.authService.checkAndSetActiveAccount();
    }
  }
  logScroll() {}
  ngOnDestroy(): void {
    if (!this.plannersDown) {
      this.authService.destroy();
    }
  }
}
