import { AdminComponent } from './shared/components/admin/admin.component';
import { StylesComponent } from './styles/styles.component';
import { AdjustmentLineTableEntity } from './models/AdjustmentLineSaleEnity';
import { HomeComponent } from './shared/components/home/home.component';
import { PlannerComponent } from './planner/planner.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SalesDetailComponent } from './planner/sales-detail/sales-detail.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginErrorComponent } from './login-error/login-error.component';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
  {
    path: 'salesreports',
    loadChildren: () =>
      import('./sales/sales.module').then((m) => m.SalesModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'planner',
    component: PlannerComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'planner/:supplierIDs',
    component: PlannerComponent,
    canActivate: [MsalGuard],
  },
  { path: 'styles', component: StylesComponent, canActivate: [MsalGuard] },
  {
    // Needed for hash routing
    path: 'code',
    component: HomeComponent,
  },
  {
    path: '',
    component: HomeComponent,
  },
  { path: 'loginerror/:errorType', component: LoginErrorComponent },
  { path: '**', redirectTo: '/' },
];
const isIframe =
  BrowserUtils.isInIframe() &&
  !window.opener &&
  window.location.href.indexOf('logout') < 0; // Remove this line to use Angular Universal
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      // Don't perform initial navigation in iframes ort popups, except for logout
      initialNavigation: !isIframe ? 'enabled' : 'disabled', // Remove this line to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
