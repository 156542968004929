import { DateRange } from './../models/DateRange';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  share,
  skip,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  NamedSalesReportSettingsTableEntity,
  SalesReportSettingsTableEntity,
} from '../models/SalesReportSettingsTableEntity';

import { EndpointsService } from './endpoints.service';
import { DataService } from './data.service';
import { SlicerDeselectedValues } from 'src/app/sales/models/Slicer';
import { Route } from '@angular/compiler/src/core';
import { ActivatedRoute } from '@angular/router';
import { SalesReportPersistantSettingsTableEntity } from '../models/SalesReportPersistantSettingsTableEntity';
import { AuthService } from 'src/app/shared/services/auth.service';
let settings: SalesReportSettingsTableEntity;
export type settingsKey = keyof typeof settings;
let persistantSettings: SalesReportPersistantSettingsTableEntity;
export type persistantSettingsKey = keyof typeof persistantSettings;
@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private endpoints: EndpointsService,
    private dataService: DataService,
    private route: ActivatedRoute
  ) {}

  private readonly persistantSettingsEndpoint: string =
    'SalesReportPersistantSettings';
  private readonly persistantSettingsPropertyNames = {
    namedDateRange: 'namedDateRange',
    notDisplayedColumns: 'notDisplayedColumns',
  };
  private _persistantSettings: BehaviorSubject<SalesReportPersistantSettingsTableEntity> =
    new BehaviorSubject<SalesReportPersistantSettingsTableEntity>(
      this.initPersistantSettings()
    );
  private persistantSettings$: Observable<SalesReportPersistantSettingsTableEntity> =
    this._persistantSettings.asObservable();
  private isValidPersistantSettingsKey(
    key: string
  ): key is persistantSettingsKey {
    return true;
  }
  initPersistantSettings(): SalesReportPersistantSettingsTableEntity {
    return {
      partitionKey: '',
      rowKey: '',
      namedDateRange: '',
      notDisplayedColumns: [
        'productID',
        'averageCost',
        'lineCost',
        'category',
        'netWeight',
        'locationID',
        'batchSN',
        'expiryDate',
        'saleID',
        'orderDate',
        'invoiceDate',
        'customerID',
        'invoiceNumber',
        'fulfilmentStatus',
        'supplierID',
      ],
    };
  }

  updatePersistantSettingsValue(propertyName: string, setting: any) {
    console.log(propertyName, setting);

    if (this.isValidPersistantSettingsKey(propertyName)) {
      console.log('isValidPersistantSettingsKey');

      let settings: SalesReportPersistantSettingsTableEntity =
        this._persistantSettings.getValue();
      let property = settings ? settings[propertyName] : null;
      if (property != null) {
        settings[propertyName] = setting;
        console.log('property');

        this._persistantSettings.next(settings);
      }
    }
  }
  //dateRange
  private namedDateRange$: Observable<string> = this._persistantSettings.pipe(
    map((settings) => settings.namedDateRange)
  );
  getNamedDateRange() {
    return this.namedDateRange$.pipe(distinctUntilChanged());
  }
  setNamedDateRange(namedDateRange: string) {
    console.log('setNamedDateRange');

    this.updatePersistantSettingsValue(
      this.persistantSettingsPropertyNames.namedDateRange,
      namedDateRange
    );
  }
  //notdisplayed columns

  private notDisplayedColumns$: Observable<string[]> =
    this._persistantSettings.pipe(
      map((settings) => settings.notDisplayedColumns)
    );
  getNotDisplayedColumns() {
    return this.notDisplayedColumns$.pipe(distinctUntilChanged());
  }
  setNotDisplayedColumns(notDisplayedColumns: string[]) {
    this.updatePersistantSettingsValue(
      this.persistantSettingsPropertyNames.notDisplayedColumns,
      notDisplayedColumns
    );
  }

  ////Date Grouping
  private _dateGrouping: BehaviorSubject<string> = new BehaviorSubject<string>(
    'InvoiceDate'
  );
  private dateGrouping$: Observable<string> = this._dateGrouping.asObservable();
  getDateGrouping() {
    return this.dateGrouping$.pipe(distinctUntilChanged());
  }
  setDateGrouping(state: string) {
    this._dateGrouping.next(state);
  }
  //Date Range
  private _dateRange: BehaviorSubject<DateRange> =
    new BehaviorSubject<DateRange>(this.getOpeningDateRange());
  private dateRange$: Observable<DateRange> = this._dateRange.asObservable();
  getDateRange() {
    return this.dateRange$.pipe(distinctUntilChanged());
  }
  setDateRange(state: DateRange) {
    console.log('setting date range');

    this._dateRange.next({ ...state });
  }
  // --------------------------------------------------------------------------------------------
  //settings
  private readonly settingsEndpoint: string = 'SalesReportSettings';
  private readonly settingsPropertyNames = {
    xAxis: 'xAxis',
    yAxis: 'yAxis',
    show: 'show',
    grouping: 'grouping',
    chartType: 'chartType',
    slicerDeselectedValues: 'slicerDeselectedValues',
    partitionType: 'partitionType',
    ids: 'ids',
  };

  //----the following are saved in the DB
  //settings
  private _settings: BehaviorSubject<SalesReportSettingsTableEntity> =
    new BehaviorSubject<SalesReportSettingsTableEntity>(this.initSettings());
  private settings$: Observable<SalesReportSettingsTableEntity> = this._settings
    .asObservable()
    .pipe(share());
  private isValidKey(key: string): key is settingsKey {
    return true;
  }

  //returns a string that defins the current settings
  currentSettings$: Observable<string> = this.settings$.pipe(
    switchMap((settings) => {
      return this.dataService.partitionTypes$.pipe(
        map((partitionTypes) => {
          const partitionType = partitionTypes.find(
            (partitionType) =>
              this.removeSpaces(partitionType.name) ==
              this.removeSpaces(settings.partitionType)
          );

          let currentSettings =
            settings.show +
            ' Sales by ' +
            this.dataService.yAxisOptions.find(
              (option) => option.id == settings.yAxis
            )?.name +
            ' by ' +
            this.dataService.xAxisOptions.find(
              (option) => option.id == settings.xAxis
            )?.name +
            ': ';
          if (settings.ids[0] == 'All') {
            currentSettings = currentSettings + settings.partitionType;
          } else {
            for (let i = 0; i < settings.ids.length; i++) {
              const partition = partitionType?.partitions.find(
                (partition) =>
                  this.removeSpaces(partition.id) == settings.ids[i]
              );
              currentSettings =
                currentSettings + partition?.name ??
                "Can't find partition name";
              if (i < settings.ids.length - 1) {
                currentSettings = currentSettings + ' & ';
              }
            }
          }
          return currentSettings;
        })
      );
    })
  );

  private initSettings() {
    let settings: SalesReportSettingsTableEntity = {
      xAxis: this.dataService.xAxisOptions[0].id,
      yAxis: this.dataService.yAxisOptions[0].id,
      show: this.dataService.showOptions[0],
      grouping: this.dataService.groupingOptions[0],
      chartType: 'bar',
      slicerDeselectedValues: [],
      partitionType: '',
      ids: [],
      partitionKey: '',
      rowKey: '',
    };
    return { ...settings };
  }
  private resetSettings() {
    const oldSettings = { ...this._settings.getValue() };
    let newSettings = this.initSettings();
    console.log('new settings');

    newSettings.ids = [...oldSettings.ids];
    newSettings.partitionType = oldSettings.partitionType;
    console.log({ ...newSettings });
    this._settings.next(newSettings);
  }

  private updateSettingsValue(propertyName: string, setting: any) {
    console.log(propertyName, setting);

    if (this.isValidKey(propertyName)) {
      let settings: SalesReportSettingsTableEntity = this._settings.getValue();
      let property = settings ? settings[propertyName] : null;
      if (property != null) {
        settings[propertyName] = setting;
        this._settings.next(settings);
      }
    }
  }

  //xAxis
  private xAxis$: Observable<string> = this._settings.pipe(
    map((settings) => settings.xAxis)
  );
  getXAxis() {
    return this.xAxis$.pipe(distinctUntilChanged(), debounceTime(500));
  }
  setXAxis(xAxis: string) {
    this.updateSettingsValue(this.settingsPropertyNames.xAxis, xAxis);
  }
  //yAxis
  private yAxis$: Observable<string> = this._settings.pipe(
    map((settings) => settings.yAxis)
  );
  getYAxis() {
    return this.yAxis$.pipe(distinctUntilChanged(), debounceTime(500));
  }
  setYAxis(yAxis: string) {
    this.updateSettingsValue(this.settingsPropertyNames.yAxis, yAxis);
  }

  //show
  private show$: Observable<string> = this._settings.pipe(
    map((settings) => settings.show)
  );
  getShow() {
    return this.show$.pipe(distinctUntilChanged(), debounceTime(500));
  }
  setShow(show: string) {
    this.updateSettingsValue(this.settingsPropertyNames.show, show);
  }
  //grouping
  private grouping$: Observable<string> = this._settings.pipe(
    map((settings) => settings.grouping)
  );
  getGrouping() {
    return this.grouping$.pipe(distinctUntilChanged(), debounceTime(500));
  }
  setGrouping(grouping: string) {
    this.updateSettingsValue(this.settingsPropertyNames.grouping, grouping);
  }
  //chartType
  private chartType$: Observable<string> = this._settings.pipe(
    map((settings) => settings.chartType)
  );
  getChartType() {
    return this.chartType$.pipe(distinctUntilChanged(), debounceTime(500));
  }
  setChartType(chartType: string) {
    this.updateSettingsValue(this.settingsPropertyNames.chartType, chartType);
  }

  //partitionTypeType
  private partitionType$: Observable<string> = this._settings.pipe(
    map((settings) => settings.partitionType)
  );
  getPartitionType() {
    return this.partitionType$.pipe(distinctUntilChanged(), debounceTime(500));
  }
  setPartitionType(partitionType: string) {
    this.updateSettingsValue(
      this.settingsPropertyNames.partitionType,
      partitionType
    );
  }

  //ids
  private ids$: Observable<string[]> = this._settings.pipe(
    map((settings) => settings.ids)
  );
  getIds() {
    return this.ids$.pipe(distinctUntilChanged());
  }
  setIds(ids: string[]) {
    this.updateSettingsValue(this.settingsPropertyNames.ids, ids);
  }

  //slicers
  private slicerDeselectedValues$: Observable<SlicerDeselectedValues[]> =
    this.settings$.pipe(map((settings) => settings.slicerDeselectedValues));
  getSlicerDeselectedValues() {
    return this.slicerDeselectedValues$.pipe(distinctUntilChanged());
  }
  setSlicerDeselectedValues(slicerDeselectedValues: SlicerDeselectedValues[]) {
    this.updateSettingsValue(
      this.settingsPropertyNames.slicerDeselectedValues,
      slicerDeselectedValues
    );
  }

  // ---------------------------------------------------------------------------------------------
  // Named Settings
  private _namedSettings: BehaviorSubject<
    NamedSalesReportSettingsTableEntity[]
  > = new BehaviorSubject<NamedSalesReportSettingsTableEntity[]>([]);
  private namedSettings$: Observable<NamedSalesReportSettingsTableEntity[]> =
    this._namedSettings.asObservable();
  getNamedSettings() {
    return this.namedSettings$;
  }
  addNamedSettings(namedSettings: NamedSalesReportSettingsTableEntity) {
    let namedSettingsArray = [...this._namedSettings.getValue()];
    const namedSettingIndex = namedSettingsArray.findIndex(
      (namedSetting) => namedSettings.rowKey == namedSetting.rowKey
    );
    if (namedSettingIndex != -1) {
      namedSettingsArray.splice(namedSettingIndex, 1, namedSettings);
    } else {
      namedSettingsArray.push(namedSettings);
    }
    this._namedSettings.next(namedSettingsArray);
  }

  deleteNamedSetting(namedSetting: NamedSalesReportSettingsTableEntity) {}
  applyNamedSetting(namedSetting: NamedSalesReportSettingsTableEntity) {
    const settings = {
      xAxis: namedSetting.xAxis,
      yAxis: namedSetting.yAxis,
      show: namedSetting.show,
      grouping: namedSetting.grouping,
      namedDateRange: namedSetting.namedDateRange,
      chartType: namedSetting.chartType,
      slicerDeselectedValues: namedSetting.slicerDeselectedValues,
      partitionType: namedSetting.partitionType,
      ids: namedSetting.ids,
      partitionKey: '',
      rowKey: '',
    };
    this._settings.next(settings);
  }

  private _skipNext: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  skipNextSettingsUpdate() {
    this._skipNext.next(true);
  }
  private _skipNextPersistantSettingsUpdate: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  skipNextPersistantSettingsUpdate() {
    this._skipNextPersistantSettingsUpdate.next(true);
  }
  configure() {
    this.loadNamedSettings();
    this.loadPersistantSettings();
    this.route.queryParams.subscribe((params) => {
      if (params && params.groupBy && params.ids && params.ids.length) {
        console.log('pass route validation');

        this._skipNext.next(true);
        this.setPartitionType(params.groupBy);

        if (Array.isArray(params.ids)) {
          this._skipNext.next(true);
          this.setIds(params.ids);
          this.loadSettings(params.groupBy, params.ids);
        } else {
          this._skipNext.next(true);
          this.setIds([params.ids]);
          this.loadSettings(params.groupBy, [params.ids]);
        }
      }
    });

    // when persistant settings change
    this.persistantSettings$
      .pipe(
        skip(1),
        // tap((_) => console.log('persisti', _)),
        // distinctUntilChanged(),

        switchMap((settings) => {
          if (!this._skipNextPersistantSettingsUpdate.getValue()) {
            console.log('persistant settings have changed', settings);
            console.log('skip next persistant  not true');
            return this.authService.getLoggedInUserName().pipe(
              debounceTime(2000),
              tap(() => console.log('debounce2000 passed')),
              switchMap((userName: string) => {
                if (userName) {
                  let postSettings = { ...settings };
                  console.log('valid user name');
                  postSettings.rowKey = userName;
                  postSettings.partitionKey =
                    'sales_report_persistant_settings';
                  console.log('posting settings');
                  return this.http.post<boolean>(
                    this.endpoints.getUrl(this.persistantSettingsEndpoint),
                    postSettings
                  );
                } else {
                  console.log('invalid user name');
                  return of(false);
                }
              })
            );
          } else {
            console.log('skip next persistant  was true');
            console.log('setting it to false');
            this._skipNextPersistantSettingsUpdate.next(false);
            return of(false);
          }
        })
      )
      .subscribe((_) => {
        _
          ? console.log('successfully posted persistant settings')
          : console.log('didnt post persistant settings');
      });

    //when settings are changed save to DB
    this.settings$
      .pipe(
        skip(1),
        distinctUntilChanged(),
        switchMap((settings: SalesReportSettingsTableEntity) => {
          return this.partitionType$.pipe(
            switchMap((partitionType) => {
              return this.getIds().pipe(
                switchMap((ids: string[]) => {
                  if (ids.length) {
                    settings.partitionKey = this.generatePartitionKey(
                      ids,
                      partitionType
                    );
                    if (!this._skipNext.getValue()) {
                      console.log('settings have changed', settings);
                      console.log('skip next not true');
                      return this.authService.getLoggedInUserName().pipe(
                        debounceTime(2000),
                        tap(() => console.log('debounce2000 passed')),
                        switchMap((userName: string) => {
                          if (userName) {
                            let postSettings = { ...settings };
                            console.log('valid user name');
                            postSettings.rowKey = userName;

                            console.log('posting settings');
                            return this.http.post<boolean>(
                              this.endpoints.getUrl(this.settingsEndpoint),
                              postSettings
                            );
                          } else {
                            console.log('invalid user name');
                            return of(false);
                          }
                        })
                      );
                    } else {
                      console.log('skip next was true');
                      console.log('setting it to false');
                      this._skipNext.next(false);
                      return of(false);
                    }
                  } else {
                    console.log('invalid ids', ids);
                    return of(false);
                  }
                })
              );
            })
          );
        })
      )
      .subscribe((_) => {
        _
          ? console.log('successfully posted settings')
          : console.log('didnt post settings');
      });
  }
  generatePartitionKey(ids: string[], partitionType: string): string {
    let partitionKey = partitionType;
    for (const id of ids) {
      partitionKey = partitionKey + '_' + id;
    }
    return partitionKey;
  }

  saveSettings(name: string, reportId: string = '') {
    this.authService
      .getLoggedInUserName()
      .pipe(
        switchMap((email) => {
          const settings = { ...this._settings.getValue() };
          const persistantSettings = { ...this._persistantSettings.getValue() };
          let namedSettings: NamedSalesReportSettingsTableEntity = {
            xAxis: settings.xAxis,
            yAxis: settings.yAxis,
            show: settings.show,
            grouping: settings.grouping,
            namedDateRange: persistantSettings.namedDateRange,
            chartType: settings.chartType,
            slicerDeselectedValues: settings.slicerDeselectedValues,
            partitionType: settings.partitionType,
            ids: settings.ids,
            partitionKey: email,
            rowKey: reportId,
            name: name,
          };
          return this.http.post<NamedSalesReportSettingsTableEntity>(
            this.endpoints.getUrl(this.settingsEndpoint + '/SaveSettings'),
            namedSettings
          );
        })
      )
      .subscribe((namedSetting) => this.addNamedSettings(namedSetting));
  }

  loadPersistantSettings() {
    this.authService
      .getLoggedInUserName()
      .pipe(
        switchMap((userName) => {
          if (!userName) {
            console.log('invalid userName');
          }
          return this.getPersistantSettings(userName);
        })
      )
      .subscribe((settings) => {
        if (settings) {
          console.log('got the persistant settings');
          console.log(settings);
          this._persistantSettings.next(settings);
        } else {
          console.log('no persistant settings found');
          console.log('reseting the settings');
          this._persistantSettings.next(this.initPersistantSettings());
        }
      });
  }

  loadSettings(groupBy: string, ids: string[]) {
    console.log('load settings called');

    this.authService
      .getLoggedInUserName()
      .pipe(
        switchMap((userName) => {
          if (!userName) {
            console.log('invalid userName');
          }
          return this.getSettings(userName, groupBy, ids);
        })
      )
      .subscribe((settings) => {
        if (settings) {
          console.log('got the settings');
          console.log(settings);
          this._settings.next(settings);
        } else {
          console.log('no settings found');
          console.log('reseting the settings');
          this.resetSettings();
        }
      });
  }
  loadNamedSettings() {
    this.authService
      .getLoggedInUserName()
      .pipe(
        switchMap((userName) => {
          if (!userName) {
            console.log('invalid userName');
          }
          return this.getNamedSettingsList(userName);
        })
      )
      .subscribe((settings) => {
        this._namedSettings.next(settings);
      });
  }
  getPersistantSettings(userName: string) {
    this.skipNextPersistantSettingsUpdate();
    console.log('getting persistant settings');
    return this.http.get<SalesReportPersistantSettingsTableEntity>(
      this.endpoints.getUrl(
        `${this.persistantSettingsEndpoint}?user=${userName}`
      )
    );
  }

  getSettings(userName: string, groupBy: string, ids: string[]) {
    this.skipNextSettingsUpdate();
    console.log('getting settings');
    return this.http.get<SalesReportSettingsTableEntity>(
      this.endpoints.getUrl(
        `${this.settingsEndpoint}?user=${userName}&${this.generateParams(
          ids,
          groupBy
        )}`
      )
    );
  }

  getNamedSettingsList(userName: string) {
    console.log('getting settings');
    return this.http.get<NamedSalesReportSettingsTableEntity[]>(
      this.endpoints.getUrl(`${this.settingsEndpoint}/${userName}`)
    );
  }
  private generateParams(IDs: string[], partitionType: string) {
    var params = '';
    for (let i = 0; i < IDs.length; i++) {
      params = params + 'ids=' + IDs[i];
      if (i < IDs.length - 1) {
        params = params + '&';
      }
    }
    params = params + '&partitionType=' + partitionType;
    return params;
  }
  removeSpaces(str: string) {
    return str.replace(new RegExp(' ', 'g'), '');
  }

  private getOpeningDateRange(): DateRange {
    let start = new Date();
    let end = new Date();
    start.setDate(1);
    start.setMonth(start.getMonth() - 1);
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setDate(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(999);
    return { start: start, end: end };
  }
}
