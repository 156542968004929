<!-- back button -->
<button mat-icon-button (click)="onBackClick()">
  <mat-icon>arrow_back</mat-icon>
</button>
<!-- vertical grid lines -->
<mat-slide-toggle
  [checked]="verticalGridLines$ | async"
  (change)="onVerticalGridLineChanged($event)"
  >Vertical Grid Lines</mat-slide-toggle
>
<!-- horizontal grid lines -->
<mat-slide-toggle
  [checked]="horizontalGridLines$ | async"
  (change)="onHorizontalGridLineChanged($event)"
  >Horizontal Grid Lines</mat-slide-toggle
>
