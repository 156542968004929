import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class EndpointsService {
  constructor() {}

  public isCloud = true;

  private base = !this.isCloud
    ? 'https://localhost:44373/api/'
    : 'https://apromoreportsserver.azurewebsites.net/api/';

  public getSignalRUrl() {
    return !this.isCloud
      ? 'https://localhost:44373/'
      : 'https://apromoreportsserver.azurewebsites.net/';
  }

  public getUrl(endpoint: string) {
    if (endpoint == '') {
      return this.base;
    } else {
      return this.base + endpoint;
    }
  }
}
